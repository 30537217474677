import {
    Select,
    SelectItem,
    SelectSkeleton,
} from "@carbon/react"

import { SpsColumnData, useSpsColumnAPI } from "../../hooks/useSpsColumnAPI"
import { useState } from "react"
import { useContextTranslations } from "../../utils/translations"

export type SpsDropdownProps = {
    displayId: number,
    spsSelectId: string,
    columnSelectId: string,
    onColumnSelectChange: (displayId: number) => void
}

export const SpsDropdown = (props: SpsDropdownProps) => {

    const { isLoading, data: spsData} = useSpsColumnAPI(props.displayId)
    const translations = useContextTranslations()
    const [selectedColumn, setSelectedColumn] = useState<number>(props.displayId)

    
    const getSelectedSpsId = (spsData: SpsColumnData[], displayId: number): number | undefined => {
        const sps = spsData.filter(s => s.columns.map(col => col.displayId).includes(displayId)).pop()
        if (sps !== undefined) return sps.spsId
        else {
            return undefined
        } 
    }

    if (isLoading) {
        return <SelectSkeleton />
    }

    return(
        <>
            <Select id={props.spsSelectId} labelText={translations("SPS_LABEL_TEXT")} value={getSelectedSpsId(spsData as SpsColumnData[], selectedColumn)} placeholder={translations("SPS_LABEL_TEXT")} size="sm" onChange={(event: any) => {
                const newSpsIdx = parseInt(event.target.value)
                const defaultColumn = spsData?.filter(s => s.spsId === newSpsIdx).pop()?.columns[0].displayId as number
                setSelectedColumn(defaultColumn)
                props.onColumnSelectChange(defaultColumn)
            }}>
                {spsData?.map(s => <SelectItem key={s.spsId} value={s.spsId} text={s.name}>{s.name}</SelectItem>)}
            </Select>
            <Select id={props.columnSelectId} labelText={translations("COLUMN_LABEL_TEXT")} value={selectedColumn} placeholder="Column" style={{display: 'block'}} size="sm" onChange={event => {
                props.onColumnSelectChange(parseInt(event.target.value))
                setSelectedColumn(parseInt(event.target.value))
            }}>
                {spsData?.filter(s => s.spsId === getSelectedSpsId(spsData, selectedColumn)).pop()?.columns.map(col => <SelectItem key={col.displayId} value={col.displayId} text={col.name}>{col.name}</SelectItem>)}
            </Select>
        </>
    )
}