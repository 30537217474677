import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import { GlobalMenu } from './views/menu/GlobalMenu';
import { GroupOverview } from './views/overview/GroupOverview';
import { AlarmView } from './views/alarms/AlarmView';
import { StationOverview } from './views/overview/StationOverview';
import { GraphicsView } from './views/graphs/GraphsView';
import { SettingsView } from './views/settings/SettingsView';
import { LoginView } from './views/login/LoginView';
import { GroupEditorView } from './views/GroupEditorView';
import { StationEditorView } from './views/StationEditorView';
import { Authenticated } from './components/Authenticated';
import { IsAdminView } from './components/IsAdminView';
import { AlarmContactsView } from './views/alarm_contacts/AlarmContactsView';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginView />
  },
  {
    path: "/",
    element: <Authenticated><GlobalMenu /></Authenticated>,
    children: [
      {
        path: "/:groupId/overview",
        element: <GroupOverview />,
      },
      {
        path: "/:groupId/alarms",
        element: <AlarmView />,
      },
      {
        path: "/:groupId/stations/:stationId/overview",
        element: <StationOverview />,
      },
      {
        path: "/:groupId/stations/:stationId/graphs",
        element: <GraphicsView />,
      },
      {
        path: "/:groupId/stations/:stationId/alarm-contacts",
        element: <AlarmContactsView />,
      },
      {
        path: "/editor/:groupId/overview",
        element: <IsAdminView><GroupEditorView /></IsAdminView>,
      },
      {
        path: "/editor/:groupId/stations/:stationId/overview",
        element: <IsAdminView><StationEditorView /></IsAdminView>,
      },
      {
        path: "/settings",
        element: <SettingsView />
      }
    ]
  },
]);

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

