import {
    Dropdown,
    OnChangeData
} from "@carbon/react"
import { useContextTranslations } from "../../utils/translations"

export type ColorDropdownProps = {
    id: string,
    label?: string,
    title: string,
    initialValue: string,
    onChange: (data: OnChangeData<ColorInfo>) => void
}

export type ColorInfo = {
    id: number, 
    color: string,
    label: string,
}

export const ColorDropdown = (props: ColorDropdownProps) => {

    const translations = useContextTranslations()

    const availableColors: ColorInfo[] = [
        {id: 0, color: 'black', label: translations("BLACK_LABEL_TEXT")},
        {id: 1, color: 'white', label: translations("WHITE_LABEL_TEXT")},
        {id: 2, color: 'red', label: translations("RED_LABEL_TEXT")},
        {id: 3, color: 'gray', label: translations("GREY_LABEL_TEXT")},
        {id: 4, color: 'blue', label: translations("BLUE_LABEL_TEXT")},
    ]

    const renderOption = (info: ColorInfo) => {
        return <div style={{display: "flex", alignItems: "center"}}>
            <div style={{height: 20, width: 20, marginRight: 8, border: '1px solid black', backgroundColor: info.color}}/>
            <span>{info.label}</span>
        </div>
    }

    return <Dropdown
        id={props.id}
        items={availableColors}
        itemToElement={renderOption}
        itemToString={info => info ? info.label : ''}
        label={props.label ? props.label : ''}
        titleText={props.title}
        initialSelectedItem={availableColors.filter(e => e.color === props.initialValue).pop()}
        onChange={props.onChange}
        size="sm"
    />
}