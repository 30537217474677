import { useSearchParams } from "react-router-dom"
import { useState } from "react"

import {
    ComposedModal,
    ModalBody,
    ModalFooter,
    Button,
    Stack, 
    TextInput,
    Form
} from "@carbon/react"

import {
    ArrowRight
} from "@carbon/icons-react"

import { useUserAuth } from "../../hooks/useUserAuth"

const elsoLogo = require("../../images/elso_logo.png")


const backgroundStyle = {
    backgroundColor: 'rgb(13,61,115)',
    height: '100vh'
}

const composedModalStyle = {
    backgroundColor: 'rgba(255,255,255,0)',
    boxShadow: '1px 2px 9px #F4AAB9',
    padding: '1em',
}

const logoStyle = {
    height: 50,
    marginBottom: 10
}

export const LoginView = () => {

    const { loginUser } = useUserAuth()
    const searchParams = useSearchParams()[0]
    const [loginInput, setLoginInput] = useState({username: '', password: ''})
    const isError = searchParams.get("error") === undefined ? false :  searchParams.get("error") === "1"

    /* 
        We dont use the ModalHeader provided by carbon/react because the ModalHeader component 
        has a default close option which cannot be disabled. Hence we use a div with the CSS 
        classes of carbon/react.
    */
    const Header = () => <div className="cds--modal-header">
        <img src={elsoLogo} alt="ELSO Logo" style={logoStyle}/>
        <h3 className="cds--modal-header__heading cds--type-beta">Login</h3>
    </div>

    return <div style={backgroundStyle}>
        <Form aria-label="login-form"> 
            <ComposedModal open={true} style={composedModalStyle} preventCloseOnClickOutside>
                <Header />      
                <ModalBody hasForm>
                <div>
                    <Stack gap={7}>
                        <TextInput 
                            id="login-username" 
                            labelText="Username" 
                            value={loginInput.username} 
                            onChange={e => setLoginInput({...loginInput, username: e.target.value})}
                            invalid={isError}
                            invalidText="Password or Username is incorrect"
                        />
                        <TextInput 
                            type="password" 
                            id="login-password" 
                            labelText="Password" 
                            value={loginInput.password}
                            onChange={e => setLoginInput({...loginInput, password: e.target.value})}
                            invalid={isError}
                            invalidText="Password or Username is incorrect"
                        />
                    </Stack>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        kind="primary"
                        onClick={() => loginUser(loginInput.username, loginInput.password)}
                        renderIcon={ArrowRight}   
                    >
                        Log in
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Form>
    </div >
}