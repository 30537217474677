import {
    Loading
} from "@carbon/react"

import { useParams } from "react-router-dom";
import { useGroupOverviewImageAPIMutation, useGroupOverviewImageAPIQuery } from "../hooks/useGroupOverviewImageAPI";
import { useEditorOverlaysAPIMutation, useEditorOverlaysAPIQuery } from "../hooks/useEditorOverlaysAPI";
import { useContext } from "react";
import { NotificationContext } from "./menu/GlobalMenu";
import { EditorView } from "./EditorView";
import { useContextTranslations } from "../utils/translations";
import { useIconsAPIMutation } from "../hooks/useIconsAPI";

export const GroupEditorView = () => {

    const { groupId } = useParams()
    const translations = useContextTranslations()
    const { pushNotification } = useContext(NotificationContext)
    const { isLoading: isLoadingImage, isError: isImageError, data: image} = useGroupOverviewImageAPIQuery(groupId as string)
    const { isLoading: isLoadingOverlays, data: apiOverlays} = useEditorOverlaysAPIQuery(groupId as string)
    const overlayMutation = useEditorOverlaysAPIMutation(groupId as string)
    const imageMutation = useGroupOverviewImageAPIMutation(groupId as string)
    const iconsMutation = useIconsAPIMutation()
    
    if(isLoadingOverlays) {
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }

    return <EditorView 
        overlays={apiOverlays ? apiOverlays : []} 
        imageUrl={image?.url} 
        isImageLoading={isLoadingImage} 
        isImageError={isImageError} 
        returnButtonLabel={translations("EDITOR_DEFAULT_RETURN_BUTTON_LABEL_TEXT")}
        returnButtonUrl={"/" + groupId + "/overview"}
        onElementSave={(elements) => overlayMutation.mutate(elements, {
            onSuccess: () => pushNotification({
                kind: "success",
                title: translations("EDITOR_SUCCESSFUL_ELEMENTS_UPDATE_NOTIFICATION_TEXT")
            }),
            onError: (error) => pushNotification({
                kind: "error",
                title: translations("EDITOR_ERROR_ELEMENTS_UPDATE_NOTIFICATION_TEXT"),
                caption: error.message
            }),
        })} 
        onFileUpload={(fileList: FileList | null) => imageMutation.mutate(fileList, {
            onSuccess: () => pushNotification({
                kind: "success",
                title: translations("EDITOR_SUCCESSFUL_IMAGE_UPDATE_NOTIFICATION_TEXT")
            }),
            onError: (error) => pushNotification({
                kind: "error",
                title: translations("EDITOR_ERROR_IMAGE_UPDATE_NOTIFICATION_TEXT"),
                caption: error.message
            }),
        })}
        onGraphicUpload={(fileList: FileList | null) => iconsMutation.mutate(fileList, {
            onSuccess:  () => pushNotification({
                kind: "success",
                title: translations("EDITOR_SUCCESSFUL_GRAPHIC_UPLOAD_NOTIFICATION_TEXT")
            }),
            onError: (error) => pushNotification({
                kind: "error",
                title: translations("EDITOR_ERROR_GRAPHIC_UPLOAD_NOTIFICATION_TEXT"),
                caption: error.message
            }),
        })} 
    />
}