import {
    Table,
    TableHead, 
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    DataTable,
    DataTableHeader,
    TableExpandRow,
    TableExpandedRow,
    TableExpandHeader,
    TableToolbar,
    TableToolbarContent,
    Form,
    Grid,
    Column,
    Select,
    SelectItem,
    NumberInput,
    TextInput,
    Stack,
    Button,
    TableToolbarMenu,
    TableToolbarAction,
    DataTableCell,
} from "@carbon/react"

import {
    Add,
    Save,
    TrashCan,
    Upload
} from "@carbon/icons-react"

import { BarEditOverlay, OverlayEditElement, DynamicImageOverlay, StaticTextEditOverlay, DynamicTextEditOverlay, comparisonOperators, Comp, StaticImageOverlay } from "./ElementDefs";
import { OverlayMetadata } from "../map/ElementDef";
import React, { BaseSyntheticEvent } from "react";
import { OverlayUpdater } from "../../hooks/useOverlayElements";
import { GraphicDropdown } from "./GraphicDropdown";
import { ColorDropdown } from "./ColorDropdown";
import { AlignmentDropdown } from "./AlignmentDropdown";
import { SpsDropdown } from "./SpsDropdown";
import { useContextTranslations } from "../../utils/translations";

export type OverlayTableProps = {
    elements: OverlayEditElement[],
    overlayUpdater: OverlayUpdater,
    onSave: (event: any) => void,
    onFileUpload: (event: FileList | null) => void,
    onGraphicUpload: (event: FileList | null) => void,
} 

type OverlayTableNumberInputProps = {
    id: string,
    label: string,
    value: number,
    step?: number
    onChange: (event: React.MouseEvent) => void,
    min?: number
}

const OverlayTableNumberInput = (props: OverlayTableNumberInputProps) => <NumberInput {...props} style={{ minInlineSize: "4.375rem", paddingInlineEnd: 10 }} min={props.min ? props.min : 0} size="sm" type="number" hideSteppers step={props.step ? props.step : 0.1}/>

export const OverlayTable = ({elements, overlayUpdater, onSave: postElements, onFileUpload, onGraphicUpload}: OverlayTableProps) => {

    const translations = useContextTranslations()
    const headers: DataTableHeader[] = [
        {
            key: "type",
            header: translations("OVERLAY_TABLE_TYPE_HEADER_TEXT")
        },
        {
            key: "positionX",
            header: translations("OVERLAY_TABLE_POSITION_X_HEADER_TEXT")
        },
        {
            key: "positionY",
            header: translations("OVERLAY_TABLE_POSITION_Y_HEADER_TEXT")
        },
        {
            key: "width",
            header: translations("OVERLAY_TABLE_WIDTH_HEADER_TEXT")
        },
        {
            key: "height",
            header: translations("OVERLAY_TABLE_HEIGHT_HEADER_TEXT")
        }
    ]

    const getCompLabel = (comp: Comp): string => {
        switch (comp) {
            case "eq": return translations("COMPARISON_EQ_LABEL_TEXT")
            case "lt": return translations("COMPARISON_LT_LABEL_TEXT")
            case "gt": return translations("COMPARISON_GT_LABEL_TEXT")
            case "leq": return translations("COMPARISON_LEQ_LABEL_TEXT")
            case "geq": return translations("COMPARISON_GEQ_LABEL_TEXT")
            case "neq": return translations("COMPARISON_NEQ_LABEL_TEXT")
            default: return ""
        } 
    }

    const getElementTypeLabel = (element: OverlayEditElement): string => {
        switch(element.type){
            case "BarOverlay": return translations("OVERLAY_ELEMENT_BARGRAPH_TYPE")
            case "StaticTextOverlay": return translations("OVERLAY_ELEMENT_STATIC_TEXT_TYPE")
            case "DynamicTextOverlay": return translations("OVERLAY_ELEMENT_DYNAMIC_TEXT_TYPE")
            case "DynamicImageOverlay": return translations("OVERLAY_ELEMENT_DYNAMIC_GRAPHIC_TYPE")
            case "StaticImageOverlay": return translations("OVERLAY_ELEMENT_STATIC_GRAPHIC_TYPE")
        }

    }

    const overlayElementToRowElement = (overlayElement: OverlayEditElement) => {
        return {
            id: overlayElement.metadata.id.toString(),
            type: overlayElement.type.toString(),
            positionX: overlayElement.metadata.x,
            positionY: overlayElement.metadata.y,
            width: overlayElement.metadata.width,
            height: overlayElement.metadata.height,
            meta: overlayElement.metadata
        }
    }

    const renderInputColumns = (input: React.ReactNode) => {
        const lgFieldWidth = 3
        const mdFieldWidth = 2
        const xlgFieldWidth = 3
        const maxFieldWidth = 2
        return <Column lg={lgFieldWidth} md={mdFieldWidth} xlg={xlgFieldWidth} max={maxFieldWidth}>
            {input}
        </Column>
    }

    function renderOverlayElementHeader({type, metadata: meta}: OverlayEditElement) {
        const positionXInput =  <OverlayTableNumberInput id="overlaytable-element-x-position-input" label={translations("OVERLAY_TABLE_POSITION_X_HEADER_TEXT")} value={meta.x} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateXPosition(meta.id, event.target.value)}/>
        const positionYInput = <OverlayTableNumberInput id="overlaytable-element-y-position-input" label={translations("OVERLAY_TABLE_POSITION_Y_HEADER_TEXT")} value={meta.y} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateYPosition(meta.id, event.target.value)} />
        const widthInput = <OverlayTableNumberInput id="overlaytable-element-width-input" label={translations("OVERLAY_TABLE_WIDTH_HEADER_TEXT")} value={meta.width} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateWidth(meta.id, event.target.value)} />
        if (type === "DynamicImageOverlay"){
            return <Grid>
                { [positionXInput, positionYInput, widthInput].map(renderInputColumns) }
            </Grid>
        }
        const heightInput = <OverlayTableNumberInput id="overlaytable-element-height-input" label={translations("OVERLAY_TABLE_HEIGHT_HEADER_TEXT")} value={meta.height} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateHeigth(meta.id, event.target.value)} />
        const linkTextInput = <TextInput id="overlaytable-element-link-input" labelText="Link" type="string" placeholder="/editor" size="sm" value={meta.ref} onChange={(event: any) => overlayUpdater.updateRef(meta.id, event.target.value)}/>
        return <Grid>
            { [positionXInput, positionYInput, widthInput, heightInput, linkTextInput].map(renderInputColumns) }
        </Grid>
    }

    const renderOverlayElementTypeFields = (element: OverlayEditElement) => {
        if(element.type === "BarOverlay"){
            return renderBarOverlayFields(element.data, element.metadata)
        } 
        else if (element.type === "StaticTextOverlay") {
            return renderStaticTextOverlayFields(element.data, element.metadata)
        }
        else if (element.type === "DynamicTextOverlay") {
            return renderDynamicTextOverlayFields(element.data, element.metadata)
        }
        else if (element.type === "DynamicImageOverlay") {
            return renderDynamicImageOverlayFields(element.data, element.metadata)
        }
        else if (element.type === "StaticImageOverlay") {
            return renderStaticImageOverlayFields(element.data, element.metadata)
        }
    }

    const renderStaticTextOverlayFields = (element: StaticTextEditOverlay, meta: OverlayMetadata) => {
        const textValueInput = <TextInput id="overlaytable-statictext-value-input" labelText={translations("OVERLAY_ELEMENT_TEXT_LABEL_TEXT")} type="string" size="sm" value={element.label} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateTextValue(meta.id, event.target.value)}/>
        const textSizeInput = <OverlayTableNumberInput id="overlaytable-statictext-size-input" label={translations("OVERLAY_ELEMENT_TEXT_SIZE_LABEL_TEXT")} value={element.size} step={1} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateTextSize(meta.id, event.target.value)}/>
        const textColorDropdown = <ColorDropdown id="overlaytable-statictext-color-input" title={translations("OVERLAY_ELEMENT_TEXT_COLOR_LABEL_TEXT")} initialValue={element.color} onChange={(event: any) => overlayUpdater.updateColor(meta.id, event.selectedItem?.color)}/>
        const alignmentSelect = <AlignmentDropdown id="overlaytable-statictext-alignment-input" title={translations("OVERLAY_ELEMENT_TEXT_ORIENTATION_LABEL_TEXT")} onChange={(event: any) => overlayUpdater.updateAlignment(meta.id, event.selectedItem?.alignment)} initialValue={element.alignment}/>
        return <Grid fullWidth> 
            { [textValueInput, textSizeInput, textColorDropdown, alignmentSelect].map(renderInputColumns) }
        </Grid>
    }

    const renderDynamicTextOverlayFields = (element: DynamicTextEditOverlay, meta: OverlayMetadata) => {
        const spsDropdown = <SpsDropdown 
            displayId={element.displayId} 
            spsSelectId="overlaytable-dynamictext-value-input" 
            columnSelectId="overlaytable-dynamictext-spscolumn-select"  
            onColumnSelectChange={(displayId: number) => overlayUpdater.updateDataColumnId(meta.id, displayId)} 
        />
        const precisionInput = <OverlayTableNumberInput id="overlaytable-dynamictext-precision-input" label={translations("OVERLAY_ELEMENT_DECIMAL_PLACES_LABEL_TEXT")} value={element.precision} step={1} onChange={(event: any) => overlayUpdater.updatePrecision(meta.id, event.target.value)} />
        const textSettings1 = <>
            <TextInput id="overlaytable-dynamictext-value-input" labelText={translations("OVERLAY_ELEMENT_TEXT_LABEL_TEXT")} type="string" size="sm" value={element.label} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateTextValue(meta.id, event.target.value)}/>
            <OverlayTableNumberInput id="overlaytable-dynamictext-size-input" label={translations("OVERLAY_ELEMENT_TEXT_SIZE_LABEL_TEXT")} value={element.size} step={1} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateTextSize(meta.id, event.target.value)}/>
        </>
        const textSettings2 = <>
            <ColorDropdown id="overlaytable-dynamictext-color-input" title={translations("OVERLAY_ELEMENT_TEXT_COLOR_LABEL_TEXT")} initialValue={element.color} onChange={(event: any) => overlayUpdater.updateColor(meta.id, event.selectedItem?.color)}/>
            <AlignmentDropdown id="overlaytable-dynamictext-alignment-input" title={translations("OVERLAY_ELEMENT_TEXT_ORIENTATION_LABEL_TEXT")} onChange={(event: any) => overlayUpdater.updateAlignment(meta.id, event.selectedItem?.alignment)} initialValue={element.alignment} />
        </>
        return <Grid fullWidth> 
            { [ spsDropdown, textSettings1, textSettings2, precisionInput].map(renderInputColumns) }
        </Grid>
    }

    const renderBarOverlayFields = (element: BarEditOverlay, meta: OverlayMetadata) => {
        const spsDropdown = <SpsDropdown 
            displayId={element.displayId} 
            spsSelectId="overlaytable-bargraph-sps-select" 
            columnSelectId="overlaytable-bargraph-spscolumn-select"  
            onColumnSelectChange={(displayId: number) => overlayUpdater.updateDataColumnId(meta.id, displayId)} 
        />
        const textColorDropdown = <ColorDropdown id="overlaytable-bargraph-color-input" title={translations("OVERLAY_ELEMENT_TEXT_COLOR_LABEL_TEXT")} initialValue={element.color} onChange={(event: any) => overlayUpdater.updateColor(meta.id, event.selectedItem?.color)}/>
        return <Grid fullWidth>
            { [spsDropdown, textColorDropdown].map(renderInputColumns) }
        </Grid>
    }

    const renderDynamicImageOverlayFields = (element: DynamicImageOverlay, meta: OverlayMetadata) => {
        const spsDropdown = <SpsDropdown 
            displayId={element.displayId} 
            spsSelectId="overlaytable-bargraph-sps-select" 
            columnSelectId="overlaytable-bargraph-spscolumn-select"  
            onColumnSelectChange={(displayId: number) => overlayUpdater.updateDataColumnId(meta.id, displayId)} 
        />
        const comparisionSettings = <>
            <Select id="overlaytable-gif-condition-select" labelText={translations("COMPARISON_LABEL_TEXT")} value={element.condition.comp} placeholder="Column" style={{display: 'block'}} size="sm" onChange={event => overlayUpdater.updateComparison(meta.id, event.target.value)}>
                {comparisonOperators.map(element => <SelectItem value={element} text={getCompLabel(element)}>{element}</SelectItem>)}
            </Select>
            <OverlayTableNumberInput id="overlaytable-gif-conditionvalue-input" label={translations("VALUES_LABEL_TEXT")} value={element.condition.threshold} onChange={(event: BaseSyntheticEvent) => overlayUpdater.updateThreshold(meta.id, event.target.value)} /> 
        </>
        const imageSettings = <>
            <GraphicDropdown id="overlaytable-gif-onimage-select" title={translations("OVERLAY_ELEMENT_GRAPHIC_ON_LABEL_TEXT")} onChange={(event: any) => overlayUpdater.updateOnGraphic(meta.id, event.selectedItem.url)} selectedItemURL={element.onGraphic}/>
            <GraphicDropdown id="overlaytable-gif-offimage-select" title={translations("OVERLAY_ELEMENT_GRAPHIC_OFF_LABEL_TEXT")} onChange={(event: any) => overlayUpdater.updateOffGraphic(meta.id, event.selectedItem.url)} selectedItemURL={element.offGraphic}/>
        </>
        return <Grid fullWidth>
            { [spsDropdown, comparisionSettings, imageSettings].map(renderInputColumns) }
        </Grid>
    }

    const renderStaticImageOverlayFields = (element: StaticImageOverlay, meta: OverlayMetadata) => {
        const dropdown = <GraphicDropdown id="overlaytable-staticgraphic-select" title={translations("OVERLAY_ELEMENT_GRAPHIC_ON_LABEL_TEXT")} onChange={(event: any) => overlayUpdater.updateStaticImage(meta.id, event.selectedItem.url)} selectedItemURL={element.graphic}/>
        return <Grid fullWidth>
            { [dropdown].map(renderInputColumns) }
        </Grid>
    }

    const getElementByRowId = (rowId: string): OverlayEditElement => elements.filter(e => e.metadata.id === parseInt(rowId))[0]
    
    const renderTableCells = (rowId: string, cell: DataTableCell<any>): React.ReactNode => {
        const element = getElementByRowId(rowId)
        switch(cell.info.header){
            case "height": {
                const isGraphicOverlay = element.type === "DynamicImageOverlay" || element.type === "StaticImageOverlay"
                if(isGraphicOverlay){
                    return <TableCell key={cell.id}>Autom.</TableCell>
                }
                else {
                    return <TableCell key={cell.id}>{cell.value}</TableCell>
                }
            }
            case "type": return <TableCell key={cell.id}>{getElementTypeLabel(element)}</TableCell>
            default: return <TableCell key={cell.id}>{cell.value}</TableCell>
        }
    } 

    return(
        <>
            <DataTable rows={elements.map(overlayElementToRowElement)} headers={headers}
            render={
                ({
                    rows,
                    headers,
                    getToolbarProps,
                    getRowProps,
                    getExpandedRowProps,
                    getTableProps,
                }) => <>
                        <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                            <TableToolbarContent>
                                <TableToolbarMenu renderIcon={Upload as any} iconDescription={translations("OVERLAY_TABLE_UPLOAD_GRAPHIC_LABEL_TEXT")}>
                                    <TableToolbarAction onClick={() => {
                                        const fileInput = document.createElement('input');
                                        fileInput.type = 'file';
                                        fileInput.accept = '.png, .jpg, .jpeg';
                                        fileInput.multiple = false;
                                        fileInput.onchange = (event: any) => onFileUpload(event.target.files);
                                        fileInput.click();
                                    }}>
                                        Hintergrundbild
                                    </TableToolbarAction>
                                    <TableToolbarAction onClick={() => {
                                        const fileInput = document.createElement('input');
                                        fileInput.type = 'file';
                                        fileInput.accept = '.png, .jpg, .jpeg';
                                        fileInput.multiple = false;
                                        fileInput.onchange = (event: any) => onGraphicUpload(event.target.files);
                                        fileInput.click();
                                    }}>
                                        Grafik
                                    </TableToolbarAction>
                                </TableToolbarMenu>
                                <TableToolbarMenu renderIcon={Add as any} iconDescription={translations("OVERLAY_TABLE_ADD_ELEMENT_LABEL_TEXT")}>
                                    <TableToolbarAction onClick={overlayUpdater.appendBarGraphOverlay as any}>
                                        {translations("OVERLAY_ELEMENT_BARGRAPH_TYPE")}
                                    </TableToolbarAction>
                                    <TableToolbarAction onClick={overlayUpdater.appendStaticTextOverlay as any}>
                                        {translations("OVERLAY_ELEMENT_STATIC_TEXT_TYPE")}
                                    </TableToolbarAction>
                                    <TableToolbarAction onClick={overlayUpdater.appendDynamicTextOverlay as any}>
                                        {translations("OVERLAY_ELEMENT_DYNAMIC_TEXT_TYPE")}
                                    </TableToolbarAction>
                                    <TableToolbarAction onClick={overlayUpdater.appendDynamicImageOverlay as any}>
                                        {translations("OVERLAY_ELEMENT_DYNAMIC_GRAPHIC_TYPE")}
                                    </TableToolbarAction>
                                    <TableToolbarAction onClick={overlayUpdater.appendStaticImageOverlay as any}>
                                        {translations("OVERLAY_ELEMENT_STATIC_GRAPHIC_TYPE")}
                                    </TableToolbarAction>
                                </TableToolbarMenu>
                                <Button kind="primary" iconDescription={translations("SAVE_LABEL_TEXT")} renderIcon={Save} onClick={postElements}>{translations("SAVE_LABEL_TEXT")}</Button>
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()} aria-label="sample table">
                        <TableHead>
                            <TableRow>
                            <TableExpandHeader aria-controls="" aria-label=""/>
                                {headers.map((header, i) => <TableHeader>{header.header}</TableHeader>)}
                                <TableHeader />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => <React.Fragment key={row.id}>
                                {/*
                                    Type casting to 'boolean' and 'any' in the following line is necessary because getRowProps return different types than needed by TableExpandRow. 
                                    Thus type checking fails. The implementation of the data table is from https://react.carbondesignsystem.com/?path=/story/components-datatable-expansion--playground
                                    The code from the example does not compile.
                                */}
                                <TableExpandRow aria-controls="" aria-label="" isExpanded={getRowProps({row}).isExpanded as boolean} onExpand={getRowProps({row}).onExpand as any}> 
                                    {row.cells.map(cell => renderTableCells(row.id, cell))}
                                    <TableCell>
                                        <Button renderIcon={TrashCan} hasIconOnly iconDescription={translations("DELETE_LABEL_TEXT")} kind="danger--ghost" style={{paddingRight: '0'}} onClick={() => overlayUpdater.deleteElement(parseInt(row.id))}/>
                                    </TableCell>
                                </TableExpandRow>
                                <TableExpandedRow colSpan={headers.length + 2} {...getExpandedRowProps({row})}>
                                    <Form style={{marginTop: '12px', marginBottom: '12px'}}>
                                        <Stack gap={5}>
                                            {
                                                getElementByRowId(row.id) === undefined ? null : renderOverlayElementHeader(getElementByRowId(row.id))
                                            }
                                            {
                                                getElementByRowId(row.id) === undefined ? null : renderOverlayElementTypeFields(getElementByRowId(row.id))
                                            }
                                        </Stack>
                                    </Form>
                                </TableExpandedRow>
                            </React.Fragment>)}
                        </TableBody>
                        </Table>
                    </>
            } /> 
        </>
    )
}