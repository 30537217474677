import { useQuery } from "@tanstack/react-query"
import { apiHost } from "../config/apiConfig"

export type SideMenuOverviewData = {
    image: string,
    mapId: number,
    mapName: string
}

export type SideMenuStationData = {
    description: string,
    spsId: number,
    spsName: string
}

export type SideMenuGroupData = {
    description: string,
    groupId: number,
    groupName: string,
    overviews: SideMenuOverviewData[],
    stations: SideMenuStationData[]
}

export const indexAPIKey = "indexData"

const convertApiDataToSideMenuOverviewData = (apiData: any): SideMenuOverviewData => {
    const { image, map_id, name } = apiData
    if(image === undefined || map_id === undefined || name === undefined){
        throw new Error("Expected 'image', 'map_id' and 'name' properties in 'overviews' from index.json API endpoint. But one of them is undefined.")
    }
    return {
        image: image,
        mapId: map_id,
        mapName: name
    }
}

const convertApiDataToSideMenuStationData = (apiData: any): SideMenuStationData => {
    const { description, sps_id, name } = apiData
    if(description === undefined || sps_id === undefined || name === undefined){
      throw new Error("Expected 'description', 'sps_id' and 'name' properties in 'stations' from index.json API endpoint. But one of them is undefined.")
    }
    return {
        description: description,
        spsId: sps_id,
        spsName: name
    }
}

const convertApiDataToSideMenuGroupData = (apiData: any): SideMenuGroupData => {
    const { description, group_id, name, overviews, stations } = apiData
    if(description === undefined || group_id === undefined || name === undefined || overviews === undefined || stations === undefined){
        throw new Error("Expected 'description', 'group_id', 'name', 'overviews' and 'stations' properties from index.json API endpoint. But one of them is undefined.")
    }
    return {
        description: description,
        groupId: group_id,
        groupName: name,
        overviews: overviews.map(convertApiDataToSideMenuOverviewData),
        stations: stations.map(convertApiDataToSideMenuStationData)
    }
}

export const useIndexAPI = () => {
    const url = apiHost + 'index'
    const query = useQuery<SideMenuGroupData[]>({
        queryKey: [indexAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data = await res.json()
            return data.map(convertApiDataToSideMenuGroupData)
        }
    })
    return query
}