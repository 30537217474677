import {
    Dropdown
} from "@carbon/react"
import { useIconsAPI } from "../../hooks/useIconsAPI"

export type GifDropdownProps = {
    id: string,
    label?: string,
    title: string,
    onChange: any,
    selectedItemURL: string
}

export type GifInfo = {
    name: string,
    url: string
}

export const GraphicDropdown = (props: GifDropdownProps) => {

    const {data} = useIconsAPI()

    if (data === undefined) {
        return null
    }

    const renderOption = (gifInfo: GifInfo) => {
        return <div style={{display: "flex", alignItems: "center"}}>
            <img src={gifInfo.url} alt={gifInfo.name} style={{maxWidth: 20, maxHeight: 20, marginRight: 8}}/>
            <span>{gifInfo.name}</span>
        </div>
    }

    return(
        <Dropdown
            id={props.id}
            items={data}
            itemToElement={renderOption}
            itemToString={item => item ? item.name : ""}
            label={props.label ? props.label : ''}
            titleText={props.title}
            size="sm"
            onChange={props.onChange}
            selectedItem={ data.find((item) => item.url === props.selectedItemURL)}
        />
    )
}