import {
    StructuredListWrapper,
    StructuredListBody,
    StructuredListRow,
    StructuredListCell,
} from "@carbon/react"

import { StationOverviewTableData } from "../../hooks/useStationOverviewAPI"

export type StationOverviewTableProps = {
    tableData: StationOverviewTableData,
}

export const StationOverviewTable = (props: StationOverviewTableProps) => {
    return <>
        <h4>{props.tableData.title}</h4>
        <div style={{backgroundColor: 'gray', height: '0px', width: '100%', marginTop: '5px', marginBottom: '10px'}}/>
        <StructuredListWrapper isCondensed style={{maxWidth: '100%'}}>
            <StructuredListBody>
                {
                    props.tableData.rows.map(row => <StructuredListRow>
                         <StructuredListCell>{row.name}</StructuredListCell>
                        <StructuredListCell>{row.value}</StructuredListCell>
                    </StructuredListRow>
                    )
                }
            </StructuredListBody>
        </StructuredListWrapper>
    </>
}