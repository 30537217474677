import { useMutation, useQuery } from "@tanstack/react-query"
import { apiHost, queryClient } from "../config/apiConfig"

const iconsAPIKey = "iconsAPI"

export type IconsAPIData = {
    name: string
    url: string
}

export const useIconsAPI = () => {
    const url = apiHost + 'editor/icons'
    const query = useQuery<IconsAPIData[]>({
        queryKey: [iconsAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data: IconsAPIData[] = await res.json()
            return data
        }
    })
    return query
}

export const useIconsAPIMutation = () => {
    const imageUrl = apiHost + 'editor/icons'
    const mutation = useMutation({
        mutationFn: (files: FileList | null) => {
            if (files === null || files.length === 0 ) {
                throw new Error("No file selected")
            }
            const file = files[0]
            const formData = new FormData();
            formData.append("file", file);
            return fetch(imageUrl, {
                method: "POST",
                body: formData,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [iconsAPIKey] })
        }
    })
    return mutation
}