import React, { useEffect, useState } from "react"
import {
    DataTable,
    Table,
    TableHead,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    DataTableHeader,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    DatePicker,
    DatePickerInput,
    Button,
    DataTableSkeleton
} from "@carbon/react"

import MultiSelect from "@carbon/react/lib/components/MultiSelect/MultiSelect"
import { useOutletContext, useParams } from "react-router-dom"
import { FilterRemove, Download } from "@carbon/icons-react"
import { GroupAlarmsAPIReturnData, useGroupAlarmsAPI } from "../../hooks/useGroupAlarmsAPI"
import { OutletDataContext } from "../menu/GlobalMenu"
import { useContextTranslations } from "../../utils/translations"

export type AlarmTableRow = {
    id: number, 
    station: string,
    timestamp: string,
    notification: string,
    status: string
}

type TableFilter = {
    stations: string[],
    startDate: Date,
    endDate: Date
}

const defaultDayOffset = 31

export const AlarmView = () => {

    const context: OutletDataContext = useOutletContext()
    const { groupId } = useParams()
    const translations = useContextTranslations()
    const defaultFromDate = new Date()
    const defautlToDate = new Date(defaultFromDate)
    defautlToDate.setDate(defautlToDate.getDate() + defaultDayOffset);

    const headers: DataTableHeader[] = [
        {
            key: "id",
            header: "ID"
        },
        {
            key: "station",
            header: translations("ALARM_VIEW_STATION_HEADER_LABEL_TEXT")
        },
        {
            key: "timestamp",
            header: translations("ALARM_VIEW_DATE_HEADER_LABEL_TEXT")
        },
        {
            key: "notification",
            header: translations("ALARM_VIEW_NOTIFICATION_HEADER_LABEL_TEXT")
        },
        {
            key: "status",
            header: translations("ALARM_VIEW_STATE_HEADER_LABEL_TEXT")
        }
    ]

    const getUniqueStationNames = (alarms: GroupAlarmsAPIReturnData[] | undefined): string[] => {
        if (alarms === undefined){
            return []
        }
        const stationNames = alarms.map(a => a.spsName)
        return stationNames.filter((item, pos) => {
            return stationNames.indexOf(item) === pos;
        })
    }

    const defaultFilter: TableFilter = {stations: [], startDate: defaultFromDate, endDate: defautlToDate}
    const [rowFilter, setFilter] = useState<TableFilter>(defaultFilter)
    const { isLoading, data: alarms } = useGroupAlarmsAPI(groupId as string, rowFilter.startDate, rowFilter.endDate)

    useEffect(() => {
        setFilter((filters) => ({...filters, stations: getUniqueStationNames(alarms)}))
    }, [alarms])

    if (alarms === undefined || isLoading){
        return <DataTableSkeleton headers={headers} aria-label="Loading Data Table" />
    }

    const alarmsToTableRow = (alarm: GroupAlarmsAPIReturnData, idx: number) => ({
        id: idx.toString(),
        station: alarm.spsName,
        timestamp: new Date(alarm.time).toLocaleTimeString('de-DE', {year: 'numeric', month: 'long', day: 'numeric'}),
        notification: alarm.alarmText,
        status: alarm.active
    })

    const filterTableRows = (row: GroupAlarmsAPIReturnData): boolean => {
        const rowDate = new Date(row.time)
        if(rowFilter.startDate === undefined || rowFilter.endDate === undefined){
            return rowFilter.stations.includes(row.spsName)
        } else {
            return rowFilter.stations.includes(row.spsName) && rowDate >= rowFilter.startDate && rowDate <= rowFilter.endDate
        }
    }

    const updateStationsFilter = (e: any) => setFilter({...rowFilter, stations: e.selectedItems})

    const updateDateFilter = (e: any) => {
        if (e.length === 2){
            const [start, end] = e
            setFilter({...rowFilter, startDate: start, endDate: end})
        } else {
            setFilter({...rowFilter, startDate: defaultFromDate, endDate: defautlToDate})
        }
    }

    const resetFilter = () => setFilter(defaultFilter)
    
    const tableRows = alarms.filter(filterTableRows).map(alarmsToTableRow)

    const csvContent = "data:text/csv;charset=utf-8," + 
        headers.map(h => h.header).join(",") + "\n" + 
        tableRows.map(e => [e.id, e.station, e.timestamp, e.notification, e.status].join(",")).join("\n")

    return <>
        <DataTable rows={tableRows} headers={headers} isSortable={true}
            render={
                ({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps
                }) => <TableContainer title={context.getGroupNameformId(groupId as string)} description={translations("ALARM_LABEL_TEXT")}>
                    <TableToolbar>
                        <TableToolbarContent style={{flexWrap: 'wrap-reverse'}}>
                            <Button renderIcon={Download} iconDescription={translations("ALARM_VIEW_DOWNLOAD_TABLE_LABEL_TEXT")} hasIconOnly onClick={() => window.open(csvContent)} kind="ghost" />
                            <Button renderIcon={FilterRemove} iconDescription={translations("ALARM_VIEW_RESET_TABLE_FILTER_LABEL_TEXT")} hasIconOnly onClick={resetFilter} kind="ghost" />
                            <div style={{width: 300}}>
                                <MultiSelect selectedItems={rowFilter.stations} onChange={updateStationsFilter} id="station-multiselect" items={getUniqueStationNames(alarms)} selectionFeedback="top-after-reopen" label={translations("ALARM_VIEW_STATION_HEADER_LABEL_TEXT")} hideLabel initialSelectedItems={getUniqueStationNames(alarms)} size="lg"/>
                            </div>
                            <DatePicker style={{maxWidth: 'max-content'}} locale={context.userLanguage} datePickerType="range" dateFormat="d/m/Y" onClose={updateDateFilter} value={[rowFilter.startDate, rowFilter.endDate]}>
                                <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText="" hideLabel size="lg"/>
                                <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText="" hideLabel size="lg" />
                            </DatePicker>
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()} aria-label="sample table">
                        <TableHead>
                            <TableRow>
                                {headers.map(header => <TableHeader {...getHeaderProps({header}) as any}>{header.header}</TableHeader>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map(row => <React.Fragment key={row.id}>
                                        <TableRow aria-controls="" aria-label=""> 
                                            {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                                        </TableRow>
                                    </React.Fragment>
                                )
                            }
                        </TableBody>
                    </Table>
                    {
                        rows.length > 0 ? null:
                        <div style={{width: "100%", display: "flex", justifyContent: "center", padding: "10%", backgroundColor: "#f4f4f4", color: "#8d8d8d"}}>{translations("ALARM_VIEW_NO_ALARMS_NOTIFICATIONS_LABEL_TEXT")}</div>
                    }
                </TableContainer>
            } /> 
    </>
}