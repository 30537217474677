import { useNavigate, useParams, useOutletContext } from "react-router-dom"

import {
    Grid,
    Column,
    Tile,
    Button,
    ButtonSkeleton,
    Loading,
} from "@carbon/react"
import {
    CheckmarkFilled,
    Edit,
    WarningFilled
} from "@carbon/icons-react"

import { Map } from "../map/Map";
import { useStationOverviewImageAPIQuery } from "../../hooks/useStationOverviewImageAPI";
import { FlexErrorMessage } from "../../components/FlexErrorMessage";
import { StationOverviewTable } from "./StationOverviewTable";
import { UserData, useUserAuth } from "../../hooks/useUserAuth";
import { OutletDataContext } from "../menu/GlobalMenu";
import { useContextTranslations } from "../../utils/translations";
import { useStationOverviewAPI } from "../../hooks/useStationOverviewAPI";
import { StationOverviewAdminTable } from "./StationOverviewAdminTable";
import { StationOverviewAddTableButton } from "./StationOverviewAddTableButton";

export const StationOverview = () => {

    const context: OutletDataContext = useOutletContext()
    const translations = useContextTranslations()
    const { user } = useUserAuth()
    const { groupId, stationId } = useParams()
    const navigate = useNavigate()
    const { data: stationData } = useStationOverviewAPI(groupId as string, stationId as string)
    const { isLoading: isLoadingImage, isError: isImageError, data: image} = useStationOverviewImageAPIQuery(groupId as string, stationId as string)
    
    const rowStyle = {
        marginTop: '16px'
    }

    if (stationData === undefined){
        return <div style={{display: 'flex', justifyContent: 'center', margin: 50, alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }

    const renderImage = () => {
        if(isLoadingImage) {
            return <div style={{display: 'flex', justifyContent: 'center', margin: 50, alignItems: 'center'}}>
                <Loading withOverlay={false} />
            </div>
        }
        if(isImageError){
            return <FlexErrorMessage errorMessage={translations("STATIONOVERVIEW_ERROR_IMAGE_LOADING")} />
        }
        return <div style={{
            position: 'relative',
            padding: '0',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Map
                image={image?.url as string}
                overlayElements={[]}
            />  
        </div>
    }

    const renderEditButton = (user: UserData | undefined) => {
        if (user === undefined){
            return <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <ButtonSkeleton />
            </div>
        }
        else if (user.isAdmin){
            return <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button kind="ghost" iconDescription={translations("STATIONOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT")} onClick={() => navigate("/editor/" + groupId + "/stations/" + stationId + "/overview")} hasIconOnly renderIcon={Edit}>{translations("EDIT_LABEL_TEXT")}</Button>
            </div>
        }
        else {
            return null
        }
    }
    
    return(
        <Grid>
                <Column lg={16}>
                    <h1>{context.getStationNamefromId(groupId as string, stationId as string)}</h1>
                </Column>
                <Column lg={10} md={8} sm={4}>
                    <Tile style={rowStyle}>
                        { renderEditButton(user) }
                        { renderImage() }
                    </Tile>
                </Column>  
                <Column lg={6} md={8} sm={4}>
                    <Tile style={rowStyle}>
                        <h4>Infos</h4>
                        <div style={{backgroundColor: 'gray', height: '1px', width: '100%', marginTop: '5px', marginBottom: '10px'}}/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <CheckmarkFilled color="green" size={15}/>
                            <p style={{marginLeft: '5px'}}>
                                Online
                            </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <WarningFilled color="darkorange" size={15}/> 
                            <p style={{marginLeft: '5px'}}>
                                5 {translations("ALARM_LABEL_TEXT")}
                            </p>
                        </div>
                    </Tile>
                     {
                         stationData.tables.map((tableData) => user?.isAdmin ?
                         <Tile style={rowStyle}>
                             <StationOverviewAdminTable groupId={groupId as string} stationId={stationId as string} tableData={tableData} tables={stationData.tables} />
                         </Tile> 
                         : <Tile style={rowStyle}>
                             <StationOverviewTable tableData={tableData} />
                         </Tile>)
                     }
                     <StationOverviewAddTableButton user={user} groupId={groupId as string} stationId={stationId as string} />
                </Column> 
        </Grid>
    )
}