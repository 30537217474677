import { useContext, useState } from "react"
 
 import {
     Button,
     ButtonSkeleton,
     StructuredListWrapper,
     StructuredListBody,
     StructuredListRow,
     StructuredListCell,
 } from "@carbon/react"
 
 import {
     Edit,
     TrashCan
 } from "@carbon/icons-react"
 import { UserData, useUserAuth } from "../../hooks/useUserAuth"
 import { useContextTranslations } from "../../utils/translations"
 import { StationOverviewTableData, useStationOverviewAPI } from "../../hooks/useStationOverviewAPI"
 import { StationOverviewTableModal } from "./StationOverviewTableModal"
 import { TableOptions, useStationOverviewEditorAPI, useStationOverviewEditorAPIMutation } from "../../hooks/useStationOverviewEditorAPI"
 import { NotificationContext } from "../menu/GlobalMenu"
 
 export type StationOverviewTableProps = {
     groupId: string,
     stationId: string,
     tableData: StationOverviewTableData,
     tables: StationOverviewTableData[],
 }
 
 export const StationOverviewAdminTable = (props: StationOverviewTableProps) => {
 
     const { user } = useUserAuth()
     const translations = useContextTranslations()
     const { pushNotification } = useContext(NotificationContext)
     const { data: stationData } = useStationOverviewAPI(props.groupId, props.stationId)
     
     const { data: editorData } = useStationOverviewEditorAPI(props.groupId, props.stationId)
     const mutation = useStationOverviewEditorAPIMutation(stationData === undefined ? "" : stationData.map.map_id.toString())
     const [showEditTableModal, setEditTableModalOpen] = useState(false)
 
     if (stationData === undefined || editorData === undefined){
         return null
     }
     
     const computeCurrentTableOptions = () => { 
         let oldTables: {title: string, rows: any}[] = []
         for (let index = 0; index < stationData.tables.length; index++) {
             let stationTable = stationData.tables[index]
             let stationTableOptions: TableOptions[] = []
             for (let rowIndex = 0; rowIndex < stationTable.rows.length; rowIndex++) {
                 const row = stationTable.rows[rowIndex]
                 const table_option = editorData.table_options.filter(option => option.name === row.name).pop()
                 if (table_option !== undefined){
                     stationTableOptions.push(table_option)
                 }
             }
             oldTables.push({ title: stationTable.title, rows: stationTableOptions})
         }
         return oldTables
     }
 
     const deleteTableByTitle = (title: string) => {
         const oldTables = computeCurrentTableOptions()
         mutation.mutate(oldTables.filter(table => table.title !== title), {
             onSuccess: () => pushNotification({
                 kind: "success",
                 title: translations("STATIONOVERVIEW_SUCCESSFUL_DELETE_TABLE_NOTIFICATION_TEXT"),
                 timeout: 700
             }),
             onError: () => pushNotification({
                 kind: "error",
                 title: translations("STATIONOVERVIEW_ERROR_DELETE_TABLE_NOTIFICATION_TEXT"),
                 timeout: 700
             })
         })
    }

    const submitTable = (tables: any) => { 
        mutation.mutate(tables, {
            onSuccess: () => pushNotification({
                kind: "success",
                title: translations("STATIONOVERVIEW_SUCCESSFUL_SUBMIT_TABLE_NOTIFICATION_TEXT"),
                timeout: 700
            }),
            onError: () => pushNotification({
                kind: "error",
                title: translations("STATIONOVERVIEW_ERROR_SUBMIT_TABLE_NOTIFICATION_TEXT"),
                timeout: 700
        })})
        setEditTableModalOpen(false)
    }
 
     const renderButtons = (user: UserData | undefined) => {
         if (user === undefined){
             return <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                 <ButtonSkeleton />
                 <ButtonSkeleton />
             </div>            
         }
         return <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
             <Button kind="ghost" iconDescription={translations("STATIONOVERVIEW_TABLE_MODIFY_TABLE_LABEL_TEXT")} hasIconOnly renderIcon={Edit} onClick={() => setEditTableModalOpen(true)}/>
             <Button kind="ghost" iconDescription={translations("STATIONOVERVIEW_TABLE_DELETE_TABLE_LABEL_TEXT")} hasIconOnly renderIcon={TrashCan} onClick={() => deleteTableByTitle(props.tableData.title)}/>
         </div>
     }
 
     return <>
         { renderButtons(user) }
         <h4>{props.tableData.title}</h4>
         <div style={{backgroundColor: 'gray', height: '0px', width: '100%', marginTop: '5px', marginBottom: '10px'}}/>
         <StructuredListWrapper isCondensed style={{maxWidth: '100%'}}>
             <StructuredListBody>
                 {
                     props.tableData.rows.map(row => <StructuredListRow>
                         <StructuredListCell>{row.name}</StructuredListCell>
                         <StructuredListCell>{row.value}</StructuredListCell>
                     </StructuredListRow>
                     )
                 }
             </StructuredListBody>
         </StructuredListWrapper>
         <StationOverviewTableModal 
            open={showEditTableModal} 
            groupId={props.groupId} 
            stationId={props.stationId} 
            tableData={props.tableData}
            currentTables={computeCurrentTableOptions()}
            onRequestClose={() => setEditTableModalOpen(false)} 
            onSubmit={submitTable}
            />
     </>
}