import { useEffect, useState } from "react";

const LARGE_BREAKPOINT_THRESHOLD = 1056

export const useBreakpoints = () => {

    const [isLg, setLg] = useState(window.innerWidth >= LARGE_BREAKPOINT_THRESHOLD);

    useEffect(() => {
      function handleResize() {
        setLg(window.innerWidth >= LARGE_BREAKPOINT_THRESHOLD);
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return {
        isLg: isLg
    };

}