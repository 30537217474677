import {
    TextInput,
    Button,
    RadioButtonGroup,
    RadioButton,
    Stack,
    TextInputSkeleton,
    RadioButtonSkeleton
} from "@carbon/react"

import {
    Save
} from "@carbon/icons-react"
import { useUserSetting, useUserSettingMutation } from "../../hooks/useUserSettings"
import { useContext, useEffect, useState } from "react"
import { NotificationContext } from "../menu/GlobalMenu"
import { useContextTranslations } from "../../utils/translations"

export const SettingsView = () => {

    const {isLoading, data} = useUserSetting()
    const { pushNotification } = useContext(NotificationContext)
    const translations = useContextTranslations()
    const settingsMutation = useUserSettingMutation()
    const [userLanguage, setLanguage] = useState<"de" | "it" | undefined>(data?.language)
    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("")
    const [inputErrors, setInputErrors] = useState({
        oldPasswordError: "",
        newPasswordError: "",
        repeatPasswordError: "",
    })


    useEffect(() => {
        setLanguage(data?.language)
    }, [data, setLanguage])

    const isValidPassword = (oldP: string, newP: string, repeatP: string): boolean => {
        if ((newP.length > 0 || repeatP.length > 0) && oldP.length === 0){
            // Old password required on password change
            setInputErrors({...inputErrors, oldPasswordError: translations("SETTINGS_VIEW_ERROR_OLD_PASSWORD_NEEDED")})
            return false
        }
        else if (oldP.length > 0 && newP.length === 0 && repeatP.length === 0){
            // Old password has input, but no new password is provided
            setInputErrors({...inputErrors, newPasswordError: translations("SETTINGS_VIEW_ERROR_NEW_PASSWORD_NEEDED"), repeatPasswordError: translations("SETTINGS_VIEW_ERROR_NEW_PASSWORD_NEEDED")})
            return false
        }
        else if (oldP.length > 0 && newP.length > 0 && repeatP.length === 0){
            // Old password and new password has input, but new password is not repeated
            setInputErrors({...inputErrors, repeatPasswordError: translations("SETTINGS_VIEW_ERROR_REPEAT_PASSWORD")})
            return false
        }
        else if (oldP.length > 0 && newP.length === 0 && repeatP.length > 0){
            // Old password has input, but no new password is provided
            setInputErrors({...inputErrors, newPasswordError: translations("SETTINGS_VIEW_ERROR_NEW_PASSWORD_NEEDED")})
            return false
        }
        else if (oldP.length > 0 && (newP !== repeatP)){
            // Passwords dont match
            setInputErrors({...inputErrors, newPasswordError: translations("SETTINGS_VIEW_ERROR_PASSWORD_MISSMATCH"), repeatPasswordError: translations("SETTINGS_VIEW_ERROR_PASSWORD_MISSMATCH")})
            return false
        }
        else if (oldP.length > 0 && (newP.length < 8 || repeatP.length < 8)){
            // Passwords too short
            setInputErrors({...inputErrors, newPasswordError: translations("SETTINGS_VIEW_ERROR_PASSWORD_MIN_LENGTH"), repeatPasswordError: translations("SETTINGS_VIEW_ERROR_PASSWORD_MIN_LENGTH")})
            return false
        }
        return true
    }

    return(
        <>
            <h1>{translations("SETTINGS_VIEW_TITLE")}</h1>
            <div style={{maxWidth:'500px'}}>
                <div style={{marginTop: '16px'}}>
                    <h3>{translations("SETTINGS_VIEW_PASSWORD_TITLE")}</h3>
                    <div style={{marginTop: '8px'}}>
                        <Stack gap={3}>
                            {
                                isLoading ? 
                                <>
                                    <TextInputSkeleton />
                                    <TextInputSkeleton />
                                    <TextInputSkeleton />
                                </> :
                                <>
                                    <TextInput 
                                        id="text-input-old-password" 
                                        type="password" 
                                        labelText={translations("SETTINGS_VIEW_OLD_PASSWORD_LABEL_TEXT")}
                                        value={oldPassword}
                                        onChange={(e) => {
                                            setInputErrors({...inputErrors, oldPasswordError: ""})
                                            setOldPassword(e.currentTarget.value)
                                        }}
                                        invalid={inputErrors.oldPasswordError.length > 0}
                                        invalidText={inputErrors.oldPasswordError}
                                    />
                                    <TextInput 
                                        id="text-input-new-password" 
                                        type="password" 
                                        labelText={translations("SETTINGS_VIEW_NEW_PASSWORD_LABEL_TEXT")} 
                                        value={newPassword}
                                        onChange={(e) => {
                                            setInputErrors({...inputErrors, newPasswordError: ""})
                                            setNewPassword(e.currentTarget.value)
                                        }}
                                        invalid={inputErrors.newPasswordError.length > 0}
                                        invalidText={inputErrors.newPasswordError}
                                    />
                                    <TextInput 
                                        id="text-input-new-password-repeat" 
                                        type="password" 
                                        labelText={translations("SETTINGS_VIEW_NEW_PASSWORD_REPEAT_LABEL_TEXT")} 
                                        value={newPasswordRepeat}
                                        onChange={(e) => {
                                            setInputErrors({...inputErrors, repeatPasswordError: ""})
                                            setNewPasswordRepeat(e.currentTarget.value)
                                        }}
                                        invalid={inputErrors.repeatPasswordError.length > 0}
                                        invalidText={inputErrors.repeatPasswordError}
                                    />
                                </>
                            }
                        </Stack>                            
                    </div>
                </div>
                <div style={{marginTop: '16px'}}>
                    <h3>{translations("SETTINGS_VIEW_LANGUAGE_TITLE")}</h3>
                    <div style={{marginTop: '8px'}}>
                        <RadioButtonGroup name="profil-language-radio-group" defaultSelected="german" orientation="horizontal">
                            {
                                isLoading ? 
                                <>
                                    <RadioButtonSkeleton />
                                    <RadioButtonSkeleton />
                                </> :
                                <>
                                    <RadioButtonGroup legendText="" name="radio-button-language-group">
                                        <RadioButton 
                                            labelText={translations("SETTINGS_VIEW_LANGUAGE_GERMAN_LABEL_TEXT")} 
                                            checked={userLanguage === "de"} 
                                            onClick={() => setLanguage("de")}
                                            value="de" 
                                            id="radio-de" 
                                        />
                                        <RadioButton 
                                            labelText={translations("SETTINGS_VIEW_LANGUAGE_ITALIAN_LABEL_TEXT")}
                                            checked={userLanguage === "it"} 
                                            onClick={() => setLanguage("it")}
                                            value="it" 
                                            id="radio-it" 
                                        />
                                    </RadioButtonGroup>
                                </>
                            }
                        </RadioButtonGroup>
                    </div>
                </div>
                <div style={{marginTop: '48px', display: 'flex', justifyContent: 'end'}}>
                    <Button 
                        renderIcon={Save}
                        onClick={() => {
                            if(isValidPassword(oldPassword, newPassword, newPasswordRepeat)){
                                settingsMutation.mutate({
                                    language: userLanguage ? userLanguage : "de", 
                                    oldPassword: oldPassword, 
                                    newPassword: newPassword
                                },{
                                    onSuccess: () => {
                                        pushNotification({
                                            kind: "success",
                                            title: translations("SETTINGS_VIEW_TITLE"),
                                            caption: translations("SETTINGS_VIEW_SUCCESSFULL_SAVE_NOTIFICATION_TEXT")
                                        })
                                        setTimeout(() => window.location.reload(), 500)
                                    },
                                    onError: (error) => pushNotification({
                                        kind: "error",
                                        title: translations("SETTINGS_VIEW_TITLE"),
                                        caption: error.message
                                    }),
                                })
                                setOldPassword("")
                                setNewPassword("")
                                setNewPasswordRepeat("")
                            }
                        }}
                    >
                        {translations("SETTINGS_VIEW_SAVE_BUTTON_LABEL_TEXT")}
                    </Button>
                </div>
            </div>
        </>
    )
}