import {
    Loading
} from "@carbon/react"
import { useParams } from "react-router-dom"

import { EditorView } from "./EditorView"
import { useStationOverviewImageAPIMutation, useStationOverviewImageAPIQuery } from "../hooks/useStationOverviewImageAPI"
import { useContext } from "react"
import { NotificationContext } from "./menu/GlobalMenu"
import { useContextTranslations } from "../utils/translations"
import { useIconsAPIMutation } from "../hooks/useIconsAPI"



export const StationEditorView = () => {

    const { groupId, stationId } = useParams()
    const translations = useContextTranslations()
    const { pushNotification } = useContext(NotificationContext)
    const { isLoading: isLoadingImage, isError: isImageError, data: image} = useStationOverviewImageAPIQuery(groupId as string, stationId as string)
    const imageMutation = useStationOverviewImageAPIMutation(groupId as string, stationId as string)
    const iconsMutation = useIconsAPIMutation()

    const isLoadingOverlays = false
    if(isLoadingOverlays) {
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }

    return <EditorView 
        overlays={[]} 
        imageUrl={image?.url} 
        isImageLoading={isLoadingImage} 
        isImageError={isImageError} 
        returnButtonLabel={translations("EDITOR_DEFAULT_RETURN_BUTTON_LABEL_TEXT")}
        returnButtonUrl={"/" + groupId + "/stations/" + stationId + "/overview"}
        onElementSave={(elments) => {return}} //TODO: check this 
        onFileUpload={(fileList) => imageMutation.mutate(fileList, {
            onSuccess: () => pushNotification({
                kind: "success",
                title: translations("EDITOR_SUCCESSFUL_IMAGE_UPDATE_NOTIFICATION_TEXT")
            }),
            onError: (error) => pushNotification({
                kind: "error",
                title: translations("EDITOR_ERROR_IMAGE_UPDATE_NOTIFICATION_TEXT"),
                caption: error.message
            }),
        })}
        onGraphicUpload={(fileList: FileList | null) => iconsMutation.mutate(fileList, {
            onSuccess:  () => pushNotification({
                kind: "success",
                title: translations("EDITOR_SUCCESSFUL_GRAPHIC_UPLOAD_NOTIFICATION_TEXT")
            }),
            onError: (error) => pushNotification({
                kind: "error",
                title: translations("EDITOR_ERROR_GRAPHIC_UPLOAD_NOTIFICATION_TEXT"),
                caption: error.message
            }),
        })} 
    />
}