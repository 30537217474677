import React, { useContext, useState } from "react"
import {
    FlexGrid, 
    Row,
    Column,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    Button,
    Table,
    TableHead,
    TableHeader,
    TableRow,
    TableExpandRow,
    TableExpandedRow,
    TableBody,
    TableCell,
    DataTableHeader,
    TableExpandHeader,
    TextInput,
    Toggle,
    Grid,
    Loading
} from "@carbon/react"
import { useOutletContext, useParams } from "react-router-dom"
import { NotificationContext, OutletDataContext } from "../menu/GlobalMenu"
import { Add, TrashCan, Save } from "@carbon/icons-react"
import { AlarmContact, useAlarmContactsAPI, useAlarmContactsAPIMutation } from "../../hooks/useAlarmContactsAPI"
import { useContextTranslations } from "../../utils/translations"

const MAX_ALARMCONTACTS = 10

export const AlarmContactsView = () => {

    const {groupId, stationId} = useParams()
    const translations = useContextTranslations()
    const {data, isLoading} = useAlarmContactsAPI(groupId as string, stationId as string)
    const alarmContactsMutation = useAlarmContactsAPIMutation(groupId as string, stationId as string)
    const [contacts, setContacts] = useState(data)
    const context: OutletDataContext = useOutletContext()
    const { pushNotification } = useContext(NotificationContext)

    const headers: DataTableHeader[] = [
        {
            key: "name",
            header: translations("ALARM_CONTACTS_VIEW_HEADER_NAME_LABEL_TEXT")
        },
        {
            key: "email",
            header: translations("ALARM_CONTACTS_VIEW_HEADER_EMAIL_LABEL_TEXT")
        },
        {
            key: "sms",
            header: translations("ALARM_CONTACTS_VIEW_HEADER_PHONENUMBER_LABEL_TEXT")
        }
    ]

    if(isLoading || data === undefined){
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }

    const appendNewAlarmContact = (): void => {
        if(contacts !== undefined && contacts.length >= MAX_ALARMCONTACTS){
            return
        }
        let maxIdx = -1
        if (contacts !== undefined){
            maxIdx = Math.max(...contacts.map(c => parseInt(c.id)))
        }
        const newIdx = maxIdx + 1
        const newContact: AlarmContact = {
            id: newIdx.toString(),
            name: translations("ALARM_CONTACTS_VIEW_HEADER_NAME_LABEL_TEXT"), 
            email: translations("ALARM_CONTACTS_VIEW_HEADER_EMAIL_LABEL_TEXT"), 
            emailActive: false,
            sms: translations("ALARM_CONTACTS_VIEW_HEADER_PHONENUMBER_LABEL_TEXT"),
            smsActive: false 
        }
        if (contacts === undefined){
            setContacts([newContact])
        } 
        else {
            setContacts([...contacts, newContact])
        }
    }

    const getAlarmContactById = (id: string): (AlarmContact | undefined) => {
        if (contacts === undefined){
            return undefined
        }
        return contacts.filter(c => c.id === id).pop()
    }

    function updateAlarmContactById<T>(id: string, updateFunc: (e: AlarmContact, v: T) => AlarmContact, value: T) {
        setContacts(old => old?.map(contact => contact.id !== id ? contact : updateFunc(contact, value)))
    }

    const updateAlarmContactName = (oldElement: AlarmContact, name: string): AlarmContact => {
        return {...oldElement, name: name}
    }

    const updateAlarmContactEmail = (oldElement: AlarmContact, email: string): AlarmContact => {
        return {...oldElement, email: email}
    }

    const updateAlarmContactPhonenumber = (oldElement: AlarmContact, phonenumber: string): AlarmContact => {
        return {...oldElement, sms: phonenumber}
    }

    const updateAlarmContactPhonenumberActive = (oldElement: AlarmContact, checked: boolean): AlarmContact => {
        return {...oldElement, smsActive: checked}
    }

    const updateAlarmContactEmailActive = (oldElement: AlarmContact, checked: boolean): AlarmContact => {
        return {...oldElement, emailActive: checked}
    }

    const deleteContactById = (id: string) => {
        setContacts(old => old?.filter(c => c.id !== id))
    }

    return (
        <FlexGrid>
            <Row>
                <Column>
                    <DataTable rows={contacts === undefined ? [] : contacts} headers={headers} isSortable={true}
                        render={
                            ({
                                rows,
                                headers,
                                getRowProps,
                                getExpandedRowProps,
                                getTableProps,
                            }) => <TableContainer title={context.getStationNamefromId(groupId as string, stationId as string)} description={translations("ALARM_LABEL_TEXT")}>
                                <TableToolbar>
                                    <TableToolbarContent>
                                        <Button 
                                            kind="ghost" 
                                            renderIcon={Save} 
                                            hasIconOnly 
                                            iconDescription={translations("SAVE_LABEL_TEXT")} 
                                            onClick={() => alarmContactsMutation.mutate(contacts as AlarmContact[], {
                                                onSuccess: () => pushNotification({
                                                    kind: "success",
                                                    title: translations("ALARM_CONTACTS_VIEW_NOTIFICATION_TITLE"),
                                                    subtitle: translations("ALARM_CONTACTS_VIEW_NOTIFICATION_SUCCESSFUL_UPDATE_TEXT")
                                                }),
                                                onError: () => pushNotification({
                                                    kind: "error",
                                                    title: translations("ALARM_CONTACTS_VIEW_NOTIFICATION_TITLE"),
                                                    subtitle: translations("ALARM_CONTACTS_VIEW_NOTIFICATION_ERROR_UPDATE_TEXT")
                                                }),
                                            })}
                                        />
                                        <Button renderIcon={Add} disabled={contacts !== undefined && contacts.length >= MAX_ALARMCONTACTS} hasIconOnly iconDescription={translations("ADD_LABEL_TEXT")} onClick={appendNewAlarmContact}/>
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()} aria-label="sample table">
                                    <TableHead>
                                        <TableRow>
                                            <TableExpandHeader aria-controls="" aria-label=""/>
                                            <TableHeader></TableHeader>
                                            {headers.map((header, i) => <TableHeader>{header.header}</TableHeader>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, rowNumber) => <React.Fragment key={row.id}>
                                            {/*
                                                Type casting to 'boolean' and 'any' in the following line is necessary because getRowProps return different types than needed by TableExpandRow. 
                                                Thus type checking fails. The implementation of the data table is from https://react.carbondesignsystem.com/?path=/story/components-datatable-expansion--playground
                                                The code from the example does not compile.
                                            */}
                                            <TableExpandRow aria-controls="" aria-label="" isExpanded={getRowProps({row}).isExpanded as boolean} onExpand={getRowProps({row}).onExpand as any}> 
                                                <TableCell key="rowNumber">{rowNumber+1}</TableCell>
                                                {row.cells.map(cell => <TableCell key={cell.id}>{cell.value}</TableCell>)}
                                            </TableExpandRow>
                                            <TableExpandedRow colSpan={headers.length + 2} {...getExpandedRowProps({row})}>
                                                <div>
                                                    <Grid fullWidth>
                                                        <Column lg={16} md={8} xlg={16} max={16} sm={4} style={{marginBottom: "10px"}}>
                                                            <TextInput id={"alarm-contact-" + row.id + "-name"} type="text" labelText={translations("ALARM_CONTACTS_VIEW_HEADER_NAME_LABEL_TEXT")} value={getAlarmContactById(row.id)?.name} onChange={(event) => updateAlarmContactById(row.id, updateAlarmContactName, event.target.value)}/>
                                                        </Column>
                                                        <Column lg={8} md={4} xlg={8} max={8} sm={4} style={{display: "flex", marginBottom: "10px"}}>
                                                            <TextInput id={"alarm-contact-" + row.id + "-email"} style={{marginRight: "20px"}} type="text" labelText={translations("ALARM_CONTACTS_VIEW_HEADER_EMAIL_LABEL_TEXT")} disabled={!getAlarmContactById(row.id)?.emailActive} value={getAlarmContactById(row.id)?.email} onChange={(event) => updateAlarmContactById(row.id, updateAlarmContactEmail, event.target.value)}/>
                                                            <Toggle labelText={translations("ALARM_CONTACTS_VIEW_EMAIL_ACTIVE_LABEL_TEXT")} labelA="" labelB="" id={"alarm-contact-" + row.id + "-email-active"} toggled={getAlarmContactById(row.id)?.emailActive} onToggle={checked => updateAlarmContactById(row.id, updateAlarmContactEmailActive, checked)}/>
                                                        </Column>
                                                        <Column lg={8} md={4} xlg={8} max={8} sm={4} style={{display: "flex", marginBottom: "10px"}}>
                                                            <TextInput id={"alarm-contact-" + row.id + "-phonenumber"} style={{marginRight: "20px"}} type="text" labelText={translations("ALARM_CONTACTS_VIEW_HEADER_PHONENUMBER_LABEL_TEXT")} disabled={!getAlarmContactById(row.id)?.smsActive} value={getAlarmContactById(row.id)?.sms} onChange={(event) => updateAlarmContactById(row.id, updateAlarmContactPhonenumber, event.target.value)}/>
                                                            <Toggle labelText={translations("ALARM_CONTACTS_VIEW_PHONENUMBER_ACTIVE_LABEL_TEXT")} labelA="" labelB="" id={"alarm-contact-" + row.id + "-phonenumber-active"} toggled={getAlarmContactById(row.id)?.smsActive} onToggle={checked => updateAlarmContactById(row.id, updateAlarmContactPhonenumberActive, checked)} />
                                                        </Column>
                                                        <Column lg={16} md={8} xlg={16} max={16} sm={4} style={{display: "flex", justifyContent: "end", marginBottom: "10px"}}>
                                                            <Button renderIcon={TrashCan} hasIconOnly iconDescription={translations("DELETE_LABEL_TEXT")} kind="danger--ghost" style={{paddingRight: '0'}} onClick={() => deleteContactById(row.id)}/>
                                                        </Column>
                                                    </Grid>
                                                </div>
                                            </TableExpandedRow>
                                        </React.Fragment>)}
                                    </TableBody>
                                </Table>
                                {
                                    rows === undefined || rows.length > 0 ? null:
                                    <div style={{width: "100%", display: "flex", justifyContent: "center", padding: "10%", backgroundColor: "#f4f4f4", color: "#8d8d8d"}}>{translations("ALARM_CONTACTS_NO_CONTACTS_NOTIFICATIONS_LABEL_TEXT")}</div>
                                }
                            </TableContainer>
                    } /> 
                </Column>
            </Row>
        </FlexGrid>
    )
}