import { useQuery } from "@tanstack/react-query"
import { apiHost } from "../config/apiConfig"

export const groupAlarmsAPIKey = "groupAlarms"

type GroupAlarmsAPIData = {
    active: number, 
    alarm: number, 
    alarm_text: string,
    sps_descprition: string,
    sps_name: string,
    time: string
}

export type GroupAlarmsAPIReturnData = {
    active: number, 
    alarm: number, 
    alarmText: string,
    spsDescprition: string,
    spsName: string,
    time: Date
}

const apiDataToReturnData = (apiData: GroupAlarmsAPIData): GroupAlarmsAPIReturnData => {
    return {
        active: apiData.active, 
        alarm: apiData.alarm, 
        alarmText: apiData.alarm_text,
        spsDescprition: apiData.sps_descprition,
        spsName: apiData.sps_name,
        time: new Date(apiData.time)
    }
}

export const useGroupAlarmsAPI = (groupId: string, from: Date, to: Date) => {
    const fromDate = from.toISOString().slice(0,10) + "T00:00:00"
    const toDate = to.toISOString().slice(0,10) + "T00:00:00"
    const url = apiHost + groupId + "/alarms?from=" + fromDate + "&to=" + toDate
    const query = useQuery<GroupAlarmsAPIReturnData[]>({
        queryKey: [groupAlarmsAPIKey, groupId, from, to],
        queryFn: async () => {
            const res = await fetch(url)
            let data: GroupAlarmsAPIData[] = await res.json()
            return data.map(apiDataToReturnData)
        }
    })
    return query
}