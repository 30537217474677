import { useEffect, useState } from "react";

import { Comp, OverlayEditElement } from "../views/editor/ElementDefs";

export type OverlayUpdater = {
    appendBarGraphOverlay: (_: void) => void,
    appendStaticTextOverlay: (_: void) => void,
    appendDynamicTextOverlay: (_: void) => void,
    appendDynamicImageOverlay: (_: void) => void,
    appendStaticImageOverlay: (_: void) => void,
    deleteElement: (id: number) => void,
    updateXPosition: (id: number, xPositon: number) => void,
    updateYPosition: (id: number, yPosition: number) => void,
    updateWidth: (id: number, width: number) => void,
    updateHeigth: (id: number, height: number) => void,
    updateTextValue: (id: number, text: string) => void,
    updateTextSize: (id: number, size: number) => void,
    updateColor: (id: number, color: string) => void,
    updateAlignment: (id: number, color: "start" | "center" | "end") => void,
    updatePrecision: (id: number, precision: number) => void,
    updateRef: (id: number, ref: string) => void,
    updateDataColumnId: (id: number, displayId: number) => void,
    updateThreshold: (id: number, threshold: number) => void,
    updateComparison: (id: number, comp: Comp) => void,
    updateById: <T>(id: number, updateFunc: (e: OverlayEditElement, v: T) => OverlayEditElement, value: T) => void,
    updateOnGraphic: (id: number, graphic: string) => void,
    updateOffGraphic: (id: number, graphic: string) => void,
    updateStaticImage: (id: number, graphic: string) => void
}

export const useOverlayEditElements = (overlayEditElements: OverlayEditElement[]): [OverlayEditElement[], OverlayUpdater] => {

    const [elements, updateElements] = useState(overlayEditElements)

    useEffect(() => {
        updateElements(overlayEditElements)
    }, [overlayEditElements])

    function updateOverlayElementById<T>(id: number, updateFunc: (e: OverlayEditElement, v: T) => OverlayEditElement, value: T) {
        updateElements(old => old.map(elem => elem.metadata.id !== id ? elem : updateFunc(elem, value)))
    }

    const getNewIndex = () => {
        let newIdx = 0
        if(elements.length > 0){
            newIdx = Math.max(...elements.map(e => e.metadata.id)) + 1
        }
        return newIdx
    }

    const appendBarGraphOverlay = () => {
        const defaultOverlayElement: OverlayEditElement = {
            type: "BarOverlay",
            metadata: {
                id: getNewIndex(),
                x: 7.3,
                y: 42.2,
                width: 15.4,
                height: 18.4,
                ref: ''
            },
            data: {
                displayId: 1,
                color: "blue"
            }
        }
        updateElements([...elements, defaultOverlayElement])
    }

    const appendStaticTextOverlay = () => {
        const defaultStaticTextElement: OverlayEditElement = {
            type: "StaticTextOverlay",
            metadata: {
                id: getNewIndex(),
                x: 7.3,
                y: 42.2,
                width: 15.4,
                height: 18.4,
                ref: ''
            },
            data: {
                size: 12,
                label: "Beispiel Text",
                color: "black",
                alignment: "center"
            }
        }
        updateElements([...elements, defaultStaticTextElement])
    }

    const appendDynamicTextOverlay = () => {
        const defaultStaticTextElement: OverlayEditElement = {
            type: "DynamicTextOverlay",
            metadata: {
                id: getNewIndex(),
                x: 7.3,
                y: 42.2,
                width: 15.4,
                height: 18.4,
                ref: ''
            },
            data: {
                label: "Beschriftung ",
                size: 10,
                unit: "%",
                color: "black",
                precision: 2,
                displayId: 1,
                alignment: "center"
            }
        }
        updateElements([...elements, defaultStaticTextElement])
    }

    const appendDynamicImageOverlay = () => {
        const defaultDynamicImageElement: OverlayEditElement = {
            type: "DynamicImageOverlay",
            metadata: {
                id: getNewIndex(),
                x: 7.3,
                y: 42.2,
                width: 8,
                height: 15,
                ref: ''
            },
            data: {
                displayId: 1,
                condition: {
                    comp: "eq",
                    threshold: 0,
                },
                offGraphic: "off-link",
                onGraphic: "",
            }
        }
        updateElements([...elements, defaultDynamicImageElement])
    }

    const appendStaticImageOverlay = () => {
        const defaultStaticImageElement: OverlayEditElement = {
            type: "StaticImageOverlay",
            metadata: {
                id: getNewIndex(),
                x: 7.3,
                y: 42.2,
                width: 8,
                height: 15,
                ref: ''
            },
            data: {
                graphic: ''
            }
        }
        updateElements([...elements, defaultStaticImageElement])
    }

    const deleteOverlayElement = (id: number) => {
        updateElements(elements.filter(e => e.metadata.id !== id))
    }

    const updateElementXPosition = (oldElement: OverlayEditElement, xPositon: number): OverlayEditElement => {
        return {...oldElement, metadata: {...oldElement.metadata, x: xPositon}}
    }

    const updateElementYPosition = (oldElement: OverlayEditElement, yPosition: number): OverlayEditElement => {
        return {...oldElement, metadata: {...oldElement.metadata, y: yPosition}}
    }

    const updateElementWidth = (oldElement: OverlayEditElement, width: number): OverlayEditElement => {
        return {...oldElement, metadata: {...oldElement.metadata, width: width}}
    }

    const updateElementHeight = (oldElement: OverlayEditElement, height: number): OverlayEditElement => {
        return {...oldElement, metadata: {...oldElement.metadata, height: height}}
    }

    const updateTextValue = (oldElement: OverlayEditElement, text: string): OverlayEditElement => {
        switch (oldElement.type) {
            case "StaticTextOverlay": return {...oldElement, data: {...oldElement.data, label: text}}
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, label: text}}
            default: return oldElement
        }
    }

    const updateTextSize = (oldElement: OverlayEditElement, size: number): OverlayEditElement => {
        switch (oldElement.type) {
            case "StaticTextOverlay": return {...oldElement, data: {...oldElement.data, size: size}}
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, size: size}}
            default: return oldElement
        }
    }

    const updateColor = (oldElement: OverlayEditElement, color: string): OverlayEditElement => {
        switch (oldElement.type) {
            case "BarOverlay": return {...oldElement, data: {...oldElement.data, color: color}}
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, color: color}}
            case "StaticTextOverlay": return {...oldElement, data: {...oldElement.data, color: color}}
            default: return oldElement
        }
    }

    const updateAlignment = (oldElement: OverlayEditElement, alignment: "start" | "center" | "end"): OverlayEditElement => {
        switch (oldElement.type) {
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, alignment: alignment}}
            case "StaticTextOverlay": return {...oldElement, data: {...oldElement.data, alignment: alignment}}
            default: return oldElement
        }
    }

    const updatePrecision = (oldElement: OverlayEditElement, precision: number): OverlayEditElement => {
        switch (oldElement.type) {
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, precision: precision}}
            default: return oldElement
        }
    }

    const updateRef = (oldElement: OverlayEditElement, ref: string): OverlayEditElement => {
        return {...oldElement, metadata: {...oldElement.metadata, ref: ref}}
    }

    const updateDataColumnId = (oldElement: OverlayEditElement, displayId: number): OverlayEditElement => {
        switch (oldElement.type){
            case "BarOverlay": return {...oldElement, data: {...oldElement.data, displayId: displayId}}
            case "DynamicTextOverlay": return {...oldElement, data: {...oldElement.data, displayId: displayId}}
            case "DynamicImageOverlay": return {...oldElement, data: {...oldElement.data, displayId: displayId}}
            default: return oldElement
        }
    }

    const updateThreshold = (oldElement: OverlayEditElement, threshold: number): OverlayEditElement => {
        switch(oldElement.type) {
            case "DynamicImageOverlay": return {
                ...oldElement, 
                data: {
                    ...oldElement.data, 
                    condition: {...oldElement.data.condition, threshold: threshold}
                }
            }
            default: return oldElement
        }
    }

    const updateComparision = (oldElement: OverlayEditElement, comparision: Comp): OverlayEditElement => {
        switch(oldElement.type) {
            case "DynamicImageOverlay": return {
                ...oldElement, 
                data: {
                    ...oldElement.data, 
                    condition: {...oldElement.data.condition, comp: comparision}
                }
            }
            default: return oldElement
        }
    }

    const updateOnGraphic = (oldElement: OverlayEditElement, graphicURL: string): OverlayEditElement => {
        switch(oldElement.type) {
            case "DynamicImageOverlay": return {
                ...oldElement, 
                data: {
                    ...oldElement.data, 
                    onGraphic: graphicURL
                }
            }
            default: return oldElement
        }
    }


    const updateOffGraphic = (oldElement: OverlayEditElement, graphicURL: string): OverlayEditElement => {
        switch(oldElement.type) {
            case "DynamicImageOverlay": return {
                ...oldElement, 
                data: {
                    ...oldElement.data, 
                    offGraphic: graphicURL
                }
            }
            default: return oldElement
        }
    }

    const updateStaticImage = (oldElement: OverlayEditElement, graphicURL: string): OverlayEditElement => {
        switch(oldElement.type) {
            case "StaticImageOverlay": return {
                ...oldElement, 
                data: {
                    ...oldElement.data, 
                    graphic: graphicURL
                }
            }
            default: return oldElement
        }
    }

    const overlayUpdater = {
        appendBarGraphOverlay: appendBarGraphOverlay,
        appendStaticTextOverlay: appendStaticTextOverlay,
        appendDynamicTextOverlay: appendDynamicTextOverlay,
        appendDynamicImageOverlay: appendDynamicImageOverlay,
        appendStaticImageOverlay: appendStaticImageOverlay,
        deleteElement: deleteOverlayElement,
        updateXPosition: (id: number, xPositon: number) => updateOverlayElementById(id, updateElementXPosition, xPositon),
        updateYPosition: (id: number, yPosition: any) => updateOverlayElementById(id, updateElementYPosition, yPosition),
        updateWidth: (id: number, width: number) => updateOverlayElementById(id, updateElementWidth, width),
        updateHeigth: (id: number, height: number) => updateOverlayElementById(id, updateElementHeight, height),
        updateTextValue: (id: number, text: string) => updateOverlayElementById(id, updateTextValue, text),
        updateTextSize: (id: number, size: number) => updateOverlayElementById(id, updateTextSize, size),
        updateColor: (id: number, color: string) => updateOverlayElementById(id, updateColor, color),
        updateAlignment: (id: number, alignment: "start" | "center" | "end") => updateOverlayElementById(id, updateAlignment, alignment),
        updatePrecision: (id: number, precision: number) => updateOverlayElementById(id, updatePrecision, precision),
        updateRef: (id: number, ref: string) => updateOverlayElementById(id, updateRef, ref),
        updateDataColumnId: (id: number, displayId: number) => updateOverlayElementById(id, updateDataColumnId, displayId),
        updateThreshold: (id: number, threshold: number) => updateOverlayElementById(id, updateThreshold, threshold),
        updateComparison: (id: number, comp: Comp) => updateOverlayElementById(id, updateComparision, comp),
        updateOnGraphic: (id: number, graphic: string) => updateOverlayElementById(id, updateOnGraphic, graphic),
        updateOffGraphic: (id: number, graphic: string) => updateOverlayElementById(id, updateOffGraphic, graphic),
        updateStaticImage: (id: number, graphic: string) => updateOverlayElementById(id, updateStaticImage, graphic),
        updateById: updateOverlayElementById
    }

    return [
        elements,
        overlayUpdater
    ]
}