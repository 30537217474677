import { useQuery } from "@tanstack/react-query"
import { apiHost } from "../config/apiConfig"

export type StationGraphColumn = {
    column: string,
    description: string,
    name: string,
    precision: number,
    unit: string
}

export type StationGraphDataPoint = Record<string, number> & {
    time: string
}

export type StationGraphsAPIData = {
    data: StationGraphDataPoint[]
    legend: StationGraphColumn[]
}

export const stationGraphsAPIKey = "stationGraphs"

export const useStationGraphsAPI = (groupId: string, stationId: string, from: string, to: string) => {
    const url = apiHost + groupId + "/stations/" + stationId + "/history?from="+ from + "&to=" + to
    const query = useQuery<StationGraphsAPIData>({
        queryKey: [stationGraphsAPIKey, groupId, stationId, from, to],
        queryFn: async () => {
            const res = await fetch(url)
            const data = await res.json()
            return data
        }
    })
    return query
}