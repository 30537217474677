import { useMutation, useQuery } from "@tanstack/react-query"
import { apiHost, queryClient } from "../config/apiConfig"

export type APIUserSettings = {
    email: string
    fist_name: string
    is_admin: boolean
    language: "de" | "it"
    last_name: string
    loginname: string
    user_id: number
}

export type UserSettings = {
    email: string
    firstName: string
    isAdmin: boolean
    language: "de" | "it"
    lastName: string
    loginname: string
    userId: number
}

const apiUserSettingsToUserSettings = (apiData: APIUserSettings): UserSettings => {
    return {
        email: apiData.email,
        firstName: apiData.fist_name,
        isAdmin: apiData.is_admin,
        language: apiData.language,
        lastName: apiData.last_name,
        loginname: apiData.loginname,
        userId: apiData.user_id
    }
}

const settingsAPIKey = "userSettings"

export const useUserSetting = () => {
    const url = apiHost + 'settings'
    const query = useQuery<UserSettings>({
        queryKey: [settingsAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data: APIUserSettings = await res.json()
            return apiUserSettingsToUserSettings(data)
        }
    })
    return query
}

export const useUserSettingMutation = () => {
    const url = apiHost + 'settings'
    const mutation = useMutation({
        mutationFn: (data: {language: "de" | "it", oldPassword: string, newPassword: string}) => {
            const formData = new FormData();
            formData.append("old_password", data.oldPassword);
            formData.append("new_password", data.newPassword);
            formData.append("language", data.language);
            return fetch(url, {
                method: "POST",
                body: formData,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [settingsAPIKey] })
        }
    })
    return mutation
}