import { useMutation, useQuery } from "@tanstack/react-query"
import { OverlayEditElement } from "../views/editor/ElementDefs"
import { OverlayMetadata } from "../views/map/ElementDef";
import { apiHost, queryClient } from "../config/apiConfig";
import { groupOverviewAPIKey } from "./useGroupOverviewAPI";

export type APIOverlayMetadata = {
    overlay_id: number,
    x: number,
    y: number,
    width: number,
    height: number,
    ref: string
};

type APIBarEditOverlay = {
    display_id: number,
    color: string
};

type APIStaticTextEditOverlay = {
    font_size: number,
    label: string,
    color: string,
    alignment: "start" | "center" | "end"
};

type APIDynamicTextEditOverlay = {
    label: string | undefined,
    font_size: number,
    display_id: number,
    readonly unit: string,
    color: string,
    precision: number
    alignment: "start" | "center" | "end"
};

const comparisonOperators = ["lt", "gt", "eq", "leq", "geq", "neq"]
type Comp = typeof comparisonOperators[number]

type APIDynamicImageOverlay = {
    display_id: number,
    condition: Comp,
    threshold: number,
    image_if: string,
    image_else: string,
};

type APIStaticImageOverlay = {
    image: string
};

export type APIOverlayEditElement =
    { type: "BarEditOverlay", metadata: APIOverlayMetadata, data: APIBarEditOverlay } |
    { type: "StaticTextEditOverlay", metadata: APIOverlayMetadata, data: APIStaticTextEditOverlay } |
    { type: "DynamicTextEditOverlay", metadata: APIOverlayMetadata, data: APIDynamicTextEditOverlay } |
    { type: "DynamicImageEditOverlay", metadata: APIOverlayMetadata, data: APIDynamicImageOverlay } |
    { type: "StaticImageEditOverlay", metadata: APIOverlayMetadata, data: APIStaticImageOverlay }

const mapAPIMetadataToOverlayMetadata = (apiMetadata: APIOverlayMetadata): OverlayMetadata => {
    return {
        id: apiMetadata.overlay_id,
        x: apiMetadata.x,
        y: apiMetadata.y,
        width: apiMetadata.width,
        height: apiMetadata.height,
        ref: apiMetadata.ref
    }
}

const mapOverlayMetadataToAPIMetadata = (metadata: OverlayMetadata): APIOverlayMetadata => {
    return {
        overlay_id: metadata.id,
        x: metadata.x,
        y: metadata.y,
        width: metadata.width,
        height: metadata.height,
        ref: metadata.ref
    }
}

const mapAPIOverlayElementsToEditOverlayElements = (apiElement: APIOverlayEditElement): OverlayEditElement => {
    switch(apiElement.type){
        case "BarEditOverlay": return {
            type: "BarOverlay", 
            metadata: mapAPIMetadataToOverlayMetadata(apiElement.metadata), 
            data: {
                displayId: apiElement.data.display_id,
                color: apiElement.data.color
            }}
        case "StaticTextEditOverlay": return {
            type: "StaticTextOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiElement.metadata),
            data: {
                size: apiElement.data.font_size,
                label: apiElement.data.label,
                color: apiElement.data.color,
                alignment: apiElement.data.alignment
            }
        }
        case "DynamicTextEditOverlay": return {
            type: "DynamicTextOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiElement.metadata),
            data: {
                label: apiElement.data.label,
                size: apiElement.data.font_size,
                displayId: apiElement.data.display_id,
                unit: apiElement.data.unit,
                color: apiElement.data.color,
                precision: apiElement.data.precision,
                alignment: apiElement.data.alignment
            }
        }
        case "DynamicImageEditOverlay": return {
            type: "DynamicImageOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiElement.metadata),
            data: {
                displayId: apiElement.data.display_id,
                condition: {
                    comp: apiElement.data.condition,
                    threshold: apiElement.data.threshold
                },
                offGraphic: apiElement.data.image_else,
                onGraphic: apiElement.data.image_if,
            }
        }
        case "StaticImageEditOverlay": return {
            type: "StaticImageOverlay", 
            metadata: mapAPIMetadataToOverlayMetadata(apiElement.metadata),
            data: {
                graphic: apiElement.data.image
            }
        }
    }
}

const mapOverlayEditElementsToAPIOverlayEditElements = (element: OverlayEditElement): APIOverlayEditElement => {
    switch(element.type){
        case "BarOverlay": return {
            type: "BarEditOverlay", 
            metadata: mapOverlayMetadataToAPIMetadata(element.metadata),
            data: {
                display_id: element.data.displayId,
                color: element.data.color,
            }
        }
        case "StaticTextOverlay": return {
            type: "StaticTextEditOverlay",
            metadata: mapOverlayMetadataToAPIMetadata(element.metadata),
            data: {
                font_size: element.data.size,
                label: element.data.label,
                color: element.data.color,
                alignment: element.data.alignment
            }
        }
        case "DynamicTextOverlay": return {
            type: "DynamicTextEditOverlay",
            metadata: mapOverlayMetadataToAPIMetadata(element.metadata),
            data: {
                label: element.data.label,
                font_size: element.data.size,
                display_id: element.data.displayId,
                unit: element.data.unit,
                color: element.data.color,
                precision: element.data.precision,
                alignment: element.data.alignment
            }
        }
        case "DynamicImageOverlay": return {
            type: "DynamicImageEditOverlay",
            metadata: mapOverlayMetadataToAPIMetadata(element.metadata),
            data: {
                display_id: element.data.displayId,
                condition: element.data.condition.comp,
                threshold: element.data.condition.threshold,
                image_else: element.data.offGraphic,
                image_if: element.data.onGraphic,
            }
        }
        case "StaticImageOverlay": return {
            type: "StaticImageEditOverlay",
            metadata: mapOverlayMetadataToAPIMetadata(element.metadata),
            data: {
                image: element.data.graphic
            }
        }
    }
}

export const groupOverviewImageAPIKey = "mapOverlays"

export const useEditorOverlaysAPIQuery = (mapId: string) => {
    const imageUrl = apiHost + "editor/" + mapId + "/overlays"
    const query = useQuery<OverlayEditElement[]>({
        queryKey: [groupOverviewImageAPIKey, mapId],
        queryFn: async () => {
            const res = await fetch(imageUrl)
            const data: APIOverlayEditElement[] = await res.json()
            return data.map(mapAPIOverlayElementsToEditOverlayElements)
        }
    })
    return query
}

export const useEditorOverlaysAPIMutation = (mapId: string) => {
    const mutation = useMutation({
        mutationFn: (editElements: OverlayEditElement[]) => {
            const apiElements = editElements.map(mapOverlayEditElementsToAPIOverlayEditElements)
            return fetch(apiHost + "editor/" + mapId + "/overlays", {
                method: "POST",
                body: JSON.stringify(apiElements),
                headers: {
                    "Content-Type": "application/json",
                  },
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [groupOverviewImageAPIKey, mapId] })
            queryClient.invalidateQueries({ queryKey: [groupOverviewAPIKey, mapId] })
        }
    })
    return mutation
}