import {
    Dropdown,
    OnChangeData
} from "@carbon/react"
import { useContextTranslations } from "../../utils/translations"

export type AlignmentDropdownProps = {
    id: string,
    label?: string,
    title: string,
    onChange: (data: OnChangeData<AlignmentInfo>) => void,
    initialValue: "start" | "center" | "end", 
}

export type AlignmentInfo = {
    id: number, 
    alignment: "start" | "center" | "end",
    label: string
}

export const AlignmentDropdown = (props: AlignmentDropdownProps) => {

    const translations = useContextTranslations()

    const alignmentInfos: AlignmentInfo[] = [
        {id: 0, alignment: 'start', label: translations("LEFT_LABEL_TEXT")},
        {id: 1, alignment: 'center', label: translations("CENTER_LABEL_TEXT")},
        {id: 2, alignment: 'end', label: translations("RIGHT_LABEL_TEXT")},
    ]

    return <Dropdown
        id={props.id}
        items={alignmentInfos}
        itemToString={item => item ? item.label : ''}
        label={props.label ? props.label : ''}
        titleText={props.title}
        onChange={props.onChange}
        initialSelectedItem={alignmentInfos.filter(e => e.alignment === props.initialValue).pop()}
        size="sm" 
    />
}