import { useMutation, useQuery } from "@tanstack/react-query"
 import { apiHost, queryClient } from "../config/apiConfig"
 import { APIOverlayEditElement } from "./useEditorOverlaysAPI"
 import { StationOverviewTableData } from "./useStationOverviewAPI"
 
 export type TableOptions = {
    description: string | null,
    name: string,
    parent_id: number,
    position: number | null,
    row_type: string,
    sps_id: number,
    sps_name: string,
    value: string
 }

export type StationOverviewEditorAPIData = {
    map: {
        image: string,
        map_id: number,
        name: string,
    }
    overlay: APIOverlayEditElement[]
    table_options: TableOptions[]
    tables: StationOverviewTableData[]
}   

export const stationOverviewEditorAPIKey = "stationOverviewEditor"
 
export const useStationOverviewEditorAPI = (groupId: string, stationId: string) => {
    const url = apiHost + "editor/" + groupId + "/stations/" + stationId + "/overview"
    const query = useQuery<StationOverviewEditorAPIData>({
        queryKey: [stationOverviewEditorAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data = await res.json()
            return data
        }
    })
    return query
}

export const useStationOverviewEditorAPIMutation = (mapId: string) => { 
    const url = apiHost + "editor/" + mapId + "/tables"
    const mutation = useMutation({
        mutationFn: (tables: any) => {
            return fetch(url, {
                method: "POST",
                body: JSON.stringify(tables),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [stationOverviewEditorAPIKey] })
        }
    })
    return mutation
}