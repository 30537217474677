import { useNavigate } from "react-router-dom"
import { useUserAuth } from "../hooks/useUserAuth"
import { useEffect } from "react"
import {
    Loading,
} from "@carbon/react"

type AuthenticatedProps = {
    children: React.ReactNode
}

export const Authenticated = (props: AuthenticatedProps): JSX.Element => {
    const { isLoggedIn } = useUserAuth()
    const navigate = useNavigate()
    
    useEffect(() => {
        if(!isLoggedIn){
            navigate("/login")
        }
    }, [isLoggedIn, navigate])

    if(!isLoggedIn){
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    } else {
        return props.children as JSX.Element
    }
}