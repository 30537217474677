import { useMutation, useQuery } from "@tanstack/react-query"
import { apiHost, queryClient } from "../config/apiConfig"

export type StationOverviewImageAPIData = {
    url: string
}

export const stationOverviewImageAPIKey = "stationOverviewImage"

export const useStationOverviewImageAPIQuery = (groupId: string, stationId: string) => {
    const imageUrl = apiHost + groupId + "/stations/" + stationId + "/overview/image"
    const query = useQuery<StationOverviewImageAPIData>({
        queryKey: [stationOverviewImageAPIKey, groupId, stationId],
        queryFn: async () => {
            const img_res = await fetch(imageUrl)
            const img = await img_res.blob()
            return {
                url: URL.createObjectURL(img)
            }
        }
    })
    return query
}

export const useStationOverviewImageAPIMutation = (groupId: string, stationId: string) => {
    const imageUrl = apiHost + groupId + "/stations/" + stationId + "/overview/image"
    const mutation = useMutation({
        mutationFn: (files: FileList | null) => {
            if (files === null || files.length === 0 ) {
                throw new Error("No file selected")
            }
            const file = files[0]
            const formData = new FormData();
            formData.append("image", file);
            return fetch(imageUrl, {
                method: "POST",
                body: formData,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [stationOverviewImageAPIKey, groupId, stationId] })
        }
    })
    return mutation
}