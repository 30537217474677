import {
    Grid,
    Tile,
    Column,
    Loading,
    Button
} from "@carbon/react"

import { OverlayEditElement } from "./editor/ElementDefs";
import { OverlayElement } from "./map/ElementDef";
import { Map } from "./map/Map";
import { OverlayTable } from "./editor/OverlayTable";
import { useOverlayEditElements } from "../hooks/useOverlayElements";
import { useNavigate } from "react-router-dom";
import { FlexErrorMessage } from "../components/FlexErrorMessage";
import { Return } from "@carbon/icons-react";
import { useContextTranslations } from "../utils/translations";

export type EditorViewProps = {
    overlays: OverlayEditElement[],
    imageUrl: string | undefined,
    isImageLoading: boolean,
    isImageError: boolean,
    returnButtonLabel?: string,
    returnButtonUrl?: string
    onElementSave: (e: OverlayEditElement[]) => void,
    onFileUpload: (fileList: FileList | null) => void,
    onGraphicUpload: (fileList: FileList | null) => void
};

export const EditorView = (props: EditorViewProps) => {

    const navigate = useNavigate()
    const translations = useContextTranslations()
    const [overlayElements, overlayUpdater] = useOverlayEditElements(props.overlays)

    const getReturnButtonLabel = (): string => {
        return props.returnButtonLabel ? props.returnButtonLabel : translations("EDITOR_DEFAULT_RETURN_BUTTON_LABEL_TEXT")
    }
    
    const onReturnClick = () => {
        if (props.returnButtonUrl){
            navigate(props.returnButtonUrl)
        }
        else {
            navigate(-1)
        }
    }

    const renderImage = () => {
        if(props.isImageLoading) {
            return <div style={{display: 'flex', justifyContent: 'center', margin: 50, alignItems: 'center'}}>
                <Loading withOverlay={false} />
            </div>
        }
        if(props.isImageError){
            return <FlexErrorMessage errorMessage={translations("EDITOR_ERROR_ON_IMAGE_LOADING_TEXT")} />
        }
        if(props.imageUrl === undefined){
            return <FlexErrorMessage errorMessage={translations("EDITOR_ERROR_INVALID_IMAGE_URL_TEXT")} />
        }
        return <Map
            image={props.imageUrl}
            overlayElements={overlayElements.map(mapEditOverlayToDataOverlay)}
        />  
    }

    return <Grid fullWidth>
            <Column max={8} lg={16} md={8} sm={4}>
                <Tile style={{marginTop: '16px'}}>
                <Button style={{marginBottom: 8}} kind="ghost" iconDescription={getReturnButtonLabel()} renderIcon={Return} hasIconOnly onClick={onReturnClick}/>
                    { renderImage() }
                </Tile>          
            </Column>
            <Column max={8} lg={16} md={8} sm={4}>
                <div style={{marginTop: '16px'}}>
                    <OverlayTable 
                        elements={overlayElements}
                        overlayUpdater={overlayUpdater}
                        onSave={() => props.onElementSave(overlayElements)}
                        onFileUpload={props.onFileUpload}
                        onGraphicUpload={props.onGraphicUpload}
                    />
                </div>
            </Column>
    </Grid>;
}

function mapEditOverlayToDataOverlay(element: OverlayEditElement): OverlayElement {
    switch(element.type) {
        case "BarOverlay": return { 
            type: "BarOverlay", 
            metadata: element.metadata, 
            data: { 
                value: 100, 
                color: element.data.color 
            }
        }
        case "StaticTextOverlay": return {
            type: "StaticTextOverlay",
            metadata: element.metadata,
            data: {
                fontSize: element.data.size,
                alignment: element.data.alignment,
                label: element.data.label,
                color: element.data.color
            }
        }
        case "DynamicTextOverlay": return {
            type: "DynamicTextOverlay",
            metadata: element.metadata,
            data: {
                fontSize: element.data.size,
                alignment: element.data.alignment,
                label: element.data.label,
                value: 0.12345,
                unit: element.data.unit,
                color: element.data.color,
                precision: element.data.precision
            }
        }
        case "DynamicImageOverlay": return {
            type: "ImageOverlay",
            metadata: element.metadata,
            data: {
                image: element.data.onGraphic
            }
        }
        case "StaticImageOverlay": return {
            type: "ImageOverlay",
            metadata: element.metadata,
            data: {
                image: element.data.graphic
            }
        }
    }
}