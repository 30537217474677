import { useQuery } from "@tanstack/react-query"
import { apiHost } from "../config/apiConfig"

type APIUserData = {
    email: string,
    fist_name: string,
    is_admin: boolean,
    language: "de" | "it",
    last_name: string,
    loginname: string,
    user_id: number
}

export type UserData = {
    email: string,
    firstName: string,
    isAdmin: boolean,
    language: "de" | "it",
    lastName: string,
    loginName: string,
    userId: number
}

type UserAuthData = {
    user: UserData | undefined,
    isLoggedIn: boolean,
    loginUser: (username: string, password: string) => any,
    logoutUser: () => void
}

const apiUserDataToUserData = (apiData: APIUserData): UserData => {
    return {
        email: apiData.email,
        firstName: apiData.fist_name,
        isAdmin: apiData.is_admin,
        language: apiData.language,
        lastName: apiData.last_name,
        loginName: apiData.loginname,
        userId: apiData.user_id
    }
}

export const useUserAuth = (): UserAuthData => {

    const {data: userData} = useQuery<UserData>({
        queryKey: ["queryUserData"],
        queryFn: async () => {
            const response = await fetch(apiHost + "user")
            const data: APIUserData = await response.json()
            return apiUserDataToUserData(data)
        }
    })
    
    const getLoginStatus = (sessionCookieName = "carbon_session"): boolean => {
        const cookies = document.cookie.split(";").map(s => s.trim().split("="))
        const sessionCookie = cookies.filter(([key, _]) => key === sessionCookieName).pop()
        return sessionCookie !== undefined
    }

    const handleLogin = async (username: string, password: string) => {
        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)
        const response = await fetch(apiHost + "login", {
            method: "POST",
            body: formData
        })
        if (response.redirected) {
            window.location.href = response.url;
        }
    }

    const handleLogout = async () => {
        const response = await fetch(apiHost + "logout")
        if (response.redirected) {
            window.location.href = response.url;
        }
    }
    
    return {
        user: userData,
        isLoggedIn: getLoginStatus(),
        loginUser: handleLogin,
        logoutUser: handleLogout
    }
}