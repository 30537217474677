import { useOutletContext } from "react-router-dom"
import { OutletDataContext } from "../views/menu/GlobalMenu"

export type TranslationKey = 
    "LOGOUT_LABEL_TEXT" |
    "SETTINGS_LABEL_TEXT" | 
    "SETTINGS_VIEW_TITLE" |
    "SETTINGS_VIEW_PASSWORD_TITLE" |
    "SETTINGS_VIEW_OLD_PASSWORD_LABEL_TEXT" |
    "SETTINGS_VIEW_NEW_PASSWORD_LABEL_TEXT" |
    "SETTINGS_VIEW_NEW_PASSWORD_REPEAT_LABEL_TEXT" |
    "SETTINGS_VIEW_LANGUAGE_TITLE" | 
    "SETTINGS_VIEW_LANGUAGE_GERMAN_LABEL_TEXT" |
    "SETTINGS_VIEW_LANGUAGE_ITALIAN_LABEL_TEXT" |
    "SETTINGS_VIEW_SUCCESSFULL_SAVE_NOTIFICATION_TEXT" |
    "SETTINGS_VIEW_SAVE_BUTTON_LABEL_TEXT" | 
    "SETTINGS_VIEW_ERROR_OLD_PASSWORD_NEEDED" |
    "SETTINGS_VIEW_ERROR_NEW_PASSWORD_NEEDED" |
    "SETTINGS_VIEW_ERROR_REPEAT_PASSWORD" |
    "SETTINGS_VIEW_ERROR_PASSWORD_MISSMATCH" |
    "SETTINGS_VIEW_ERROR_PASSWORD_MIN_LENGTH" |
    "EDITOR_SUCCESSFUL_IMAGE_UPDATE_NOTIFICATION_TEXT" |
    "EDITOR_ERROR_IMAGE_UPDATE_NOTIFICATION_TEXT" |
    "EDITOR_SUCCESSFUL_ELEMENTS_UPDATE_NOTIFICATION_TEXT" |
    "EDITOR_ERROR_ELEMENTS_UPDATE_NOTIFICATION_TEXT" |
    "EDITOR_DEFAULT_RETURN_BUTTON_LABEL_TEXT" |
    "EDITOR_ERROR_ON_IMAGE_LOADING_TEXT" |
    "EDITOR_ERROR_INVALID_IMAGE_URL_TEXT" |
    "GROUPOVERVIEW_ERROR_IMAGE_LOADING" |
    "GROUPOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT" |
    "STATIONOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT" |
    "STATIONOVERVIEW_ERROR_IMAGE_LOADING" |
    "STATIONOVERVIEW_TABLE_MODIFY_TABLE_LABEL_TEXT" |
    "STATIONOVERVIEW_TABLE_DELETE_TABLE_LABEL_TEXT" |
    "STATIONOVERVIEW_TABLE_MODIFY_TABLE_ENTRIES_LABEL_TEXT" |
    "STATIONOVERVIEW_TABLE_MODIFY_TABLE_TITLE" |
    "STATIONOVERVIEW_SUCCESSFUL_ADD_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_ERROR_ADD_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_SUCCESSFUL_DELETE_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_ERROR_SUBMIT_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_SUCCESSFUL_SUBMIT_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_ERROR_DELETE_TABLE_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_TABLE_MODIFY_TABLETITLE_BUTTON_LABEL_TEXT" |
    "EDIT_LABEL_TEXT" |
    "ALARM_LABEL_TEXT" |
    "VALUES_LABEL_TEXT" |
    "SAVE_LABEL_TEXT" |
    "ABORT_LABEL_TEXT" |
    "GLOBAL_MENU_DETAIL_OVERVIEW_LABEL_TEXT" |
    "GLOBAL_MENU_GRAPHICS_LABEL_TEXT" |
    "GLOBAL_MENU_ALARM_CONTACTS_LABEL_TEXT" |
    "RIGHT_LABEL_TEXT" |
    "CENTER_LABEL_TEXT" |
    "LEFT_LABEL_TEXT" |
    "BLACK_LABEL_TEXT" |
    "WHITE_LABEL_TEXT" |
    "RED_LABEL_TEXT" |
    "GREY_LABEL_TEXT" |
    "BLUE_LABEL_TEXT" |
    "OVERLAY_TABLE_POSITION_X_HEADER_TEXT" |
    "OVERLAY_TABLE_POSITION_Y_HEADER_TEXT" |
    "OVERLAY_TABLE_TYPE_HEADER_TEXT" |
    "OVERLAY_TABLE_WIDTH_HEADER_TEXT" |
    "OVERLAY_TABLE_HEIGHT_HEADER_TEXT" |
    "COMPARISON_LABEL_TEXT" |
    "COMPARISON_EQ_LABEL_TEXT" |
    "COMPARISON_LT_LABEL_TEXT" |
    "COMPARISON_GT_LABEL_TEXT" |
    "COMPARISON_LEQ_LABEL_TEXT" |
    "COMPARISON_GEQ_LABEL_TEXT" |
    "OVERLAY_ELEMENT_BARGRAPH_TYPE" |
    "OVERLAY_ELEMENT_STATIC_TEXT_TYPE" |
    "OVERLAY_ELEMENT_DYNAMIC_TEXT_TYPE" |
    "OVERLAY_ELEMENT_DYNAMIC_GRAPHIC_TYPE" |
    "OVERLAY_ELEMENT_TEXT_LABEL_TEXT" |
    "OVERLAY_ELEMENT_STATIC_GRAPHIC_TYPE" |
    "OVERLAY_ELEMENT_TEXT_SIZE_LABEL_TEXT" |
    "OVERLAY_ELEMENT_TEXT_COLOR_LABEL_TEXT" |
    "OVERLAY_ELEMENT_TEXT_ORIENTATION_LABEL_TEXT" |
    "OVERLAY_ELEMENT_DECIMAL_PLACES_LABEL_TEXT" |
    "OVERLAY_ELEMENT_GRAPHIC_ON_LABEL_TEXT" |
    "OVERLAY_ELEMENT_GRAPHIC_OFF_LABEL_TEXT" |
    "OVERLAY_ELEMENT_SELECT_GRAPHIC_LABEL_TEXT" |
    "OVERLAY_TABLE_ADD_ELEMENT_LABEL_TEXT" |
    "DELETE_LABEL_TEXT" |
    "COLUMN_LABEL_TEXT" |
    "SPS_LABEL_TEXT" |
    "ALARM_VIEW_STATION_HEADER_LABEL_TEXT" |
    "ALARM_VIEW_DATE_HEADER_LABEL_TEXT" |
    "ALARM_VIEW_NOTIFICATION_HEADER_LABEL_TEXT" |
    "ALARM_VIEW_STATE_HEADER_LABEL_TEXT" |
    "ALARM_VIEW_DOWNLOAD_TABLE_LABEL_TEXT" |
    "ALARM_VIEW_RESET_TABLE_FILTER_LABEL_TEXT" |
    "ALARM_VIEW_NO_ALARMS_NOTIFICATIONS_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_HEADER_NAME_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_HEADER_EMAIL_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_HEADER_PHONENUMBER_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_EMAIL_ACTIVE_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_PHONENUMBER_ACTIVE_LABEL_TEXT" |
    "ADD_LABEL_TEXT" |
    "ALARM_CONTACTS_NO_CONTACTS_NOTIFICATIONS_LABEL_TEXT" |
    "ALARM_CONTACTS_VIEW_NOTIFICATION_TITLE" |
    "ALARM_CONTACTS_VIEW_NOTIFICATION_SUCCESSFUL_UPDATE_TEXT" |
    "ALARM_CONTACTS_VIEW_NOTIFICATION_ERROR_UPDATE_TEXT" |
    "GRAPHS_VIEW_TO_DATEPICKER_LABEL" |
    "GRAPHS_VIEW_FROM_DATEPICKER_LABEL" |
    "COMPARISON_NEQ_LABEL_TEXT" |
    "OVERLAY_TABLE_UPLOAD_GRAPHIC_LABEL_TEXT" | 
    "EDITOR_SUCCESSFUL_GRAPHIC_UPLOAD_NOTIFICATION_TEXT" |
    "EDITOR_ERROR_GRAPHIC_UPLOAD_NOTIFICATION_TEXT" |
    "STATIONOVERVIEW_TABLE_MODAL_LABEL" |
    "STATIONOVERVIEW_TABLE_ADD_TABLE_BUTTON_LABEL_TEXT"

export type TranslationInfo = {
    de: string,
    it: string
}

export const translations = {
    LOGOUT_LABEL_TEXT: {de: "Abmelden", it: "Logout"},
    SETTINGS_LABEL_TEXT: {de: "Einstellungen", it: "Impostazioni"},
    SETTINGS_VIEW_TITLE: {de: "Einstellungen", it: "Impostazioni"},
    SETTINGS_VIEW_PASSWORD_TITLE: {de: "Passwort", it: "Password"},
    SETTINGS_VIEW_OLD_PASSWORD_LABEL_TEXT: {de: "Altes Passwort", it: "Vecchia password"},
    SETTINGS_VIEW_NEW_PASSWORD_LABEL_TEXT: {de: "Neues Passwort", it: "Nuova password"},
    SETTINGS_VIEW_NEW_PASSWORD_REPEAT_LABEL_TEXT: {de: "Neues Passwort wiederholen", it: "Ripetere la nuova password"},
    SETTINGS_VIEW_LANGUAGE_TITLE: {de: "Sprache", it: "Lingua"},
    SETTINGS_VIEW_LANGUAGE_GERMAN_LABEL_TEXT: {de: "Deutsch", it: "Tedesco"},
    SETTINGS_VIEW_LANGUAGE_ITALIAN_LABEL_TEXT: {de: "Italienisch", it: "Italiano"},
    SETTINGS_VIEW_SUCCESSFULL_SAVE_NOTIFICATION_TEXT: {de: "Einstellungen wurden gespeichert", it: "Impostazioni sono state salvate"},
    SETTINGS_VIEW_SAVE_BUTTON_LABEL_TEXT: {de: "Einstellungen speichern", it: "Salva"},
    SETTINGS_VIEW_ERROR_OLD_PASSWORD_NEEDED: {de: "Altes Passwort für Passwortänderung eingeben", it: "Inserire la vecchia password"},
    SETTINGS_VIEW_ERROR_NEW_PASSWORD_NEEDED: {de: "Neues Passwort eingeben", it: "Inserire la nuova password"},
    SETTINGS_VIEW_ERROR_REPEAT_PASSWORD: {de: "Passwort wiederholen", it: "Ripetere la password"},
    SETTINGS_VIEW_ERROR_PASSWORD_MISSMATCH: {de: "Passwörter stimmen nicht überein", it: "Le password non corrispondono"},
    SETTINGS_VIEW_ERROR_PASSWORD_MIN_LENGTH: {de: "Passwörter müssen mind. 8 Zeichen lang sein", it: "Le password devono avere almeno 8 caratteri"},
    EDITOR_SUCCESSFUL_IMAGE_UPDATE_NOTIFICATION_TEXT: {de: "Bild wurde aktualisiert", it: "L'immagine è stata aggiornata"},
    EDITOR_ERROR_IMAGE_UPDATE_NOTIFICATION_TEXT: {de: "Fehler beim Aktualisieren", it: "Errore durante l'aggiornamento"},
    EDITOR_SUCCESSFUL_ELEMENTS_UPDATE_NOTIFICATION_TEXT: {de: "Elemente gespeichert", it: "Elementi salvati"},
    EDITOR_ERROR_ELEMENTS_UPDATE_NOTIFICATION_TEXT: {de: "Fehler beim Speichern", it: "Errore durante il salvataggio"},
    EDITOR_DEFAULT_RETURN_BUTTON_LABEL_TEXT: {de: "Zurück", it: "Indietro"},
    EDITOR_ERROR_ON_IMAGE_LOADING_TEXT: {de: "Fehler beim Laden des Bildes", it: "Errore di caricamento dell'immagine"},
    EDITOR_ERROR_INVALID_IMAGE_URL_TEXT: {de: "Ungültige Bild URL", it: "URL immagine non valido"},
    GROUPOVERVIEW_ERROR_IMAGE_LOADING: {de: "Bild konnte nicht geladen werden", it: "L'immagine non può essere caricata"},
    GROUPOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT: {de: "Übersicht bearbeiten", it: "Modifica"},
    STATIONOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT: {de: "Übersicht bearbeiten", it: "Modifica"},
    STATIONOVERVIEW_ERROR_IMAGE_LOADING: {de: "Bild konnte nicht geladen werden", it: "L'immagine non può essere caricata"},
    STATIONOVERVIEW_TABLE_MODIFY_TABLE_LABEL_TEXT: {de: "Tabelle bearbeiten", it: "Modifica tabella"},
    STATIONOVERVIEW_TABLE_DELETE_TABLE_LABEL_TEXT: {de: "Tabelle löschen", it: "Cancella tabella"},
    STATIONOVERVIEW_TABLE_MODIFY_TABLE_ENTRIES_LABEL_TEXT: {de: "Einträge bearbeiten", it: "Modifica voci"},
    STATIONOVERVIEW_TABLE_MODIFY_TABLE_TITLE: {de: "Wertetabelle", it: "Tabella dei valori"},
    STATIONOVERVIEW_TABLE_ADD_TABLE_BUTTON_LABEL_TEXT: {de: "Tabelle hinzufügen", it: "Aggiungi tabella"},
    STATIONOVERVIEW_TABLE_MODAL_LABEL: {de: "Übersicht bearbeiten", it: "Modifica"},
    STATIONOVERVIEW_SUCCESSFUL_ADD_TABLE_NOTIFICATION_TEXT: {de: "Tabelle hinzugefügt", it: "Tabella aggiunta"},
    STATIONOVERVIEW_ERROR_ADD_TABLE_NOTIFICATION_TEXT: {de: "Fehler beim Hinzufügen der Tabelle", it: "Errore durante l'aggiunta della tabella"},
    STATIONOVERVIEW_SUCCESSFUL_DELETE_TABLE_NOTIFICATION_TEXT: {de: "Tabelle gelöscht", it: "Tabella cancellata"},
    STATIONOVERVIEW_ERROR_DELETE_TABLE_NOTIFICATION_TEXT: {de: "Fehler beim Löschen der Tabelle", it: "Errore durante l'eliminazione della tabella"},
    STATIONOVERVIEW_TABLE_MODIFY_TABLETITLE_BUTTON_LABEL_TEXT: { de: "Titel ändern", it: "Modifica titolo"},
    EDIT_LABEL_TEXT: {de: "Bearbeiten", it: "Modifica"},
    ALARM_LABEL_TEXT: {de: "Alarm", it: "Allarme"},
    VALUES_LABEL_TEXT: {de: "Werte", it: "Valori"},
    SAVE_LABEL_TEXT: {de: "Speichern", it: "Salva"},
    ABORT_LABEL_TEXT: {de: "Abbrechen", it: "Annulla"},
    GLOBAL_MENU_DETAIL_OVERVIEW_LABEL_TEXT: {de: "Detailübersicht", it: "Dettagli"},
    GLOBAL_MENU_GRAPHICS_LABEL_TEXT: {de: "Grafik", it: "Grafica"},
    GLOBAL_MENU_ALARM_CONTACTS_LABEL_TEXT: {de: "Alarmkontakte", it: "Contatti d'allarme"},
    RIGHT_LABEL_TEXT: {de: "Rechts", it: "Destra"},
    CENTER_LABEL_TEXT: {de: "Mitte", it: "Centro"},
    LEFT_LABEL_TEXT: {de: "Links", it: "Sinistra"},
    BLACK_LABEL_TEXT: {de: "Schwarz", it: "Nero"},
    WHITE_LABEL_TEXT: {de: "Weiß", it: "Bianco"},
    RED_LABEL_TEXT: {de: "Rot", it: "Rosso"},
    GREY_LABEL_TEXT: {de: "Grau", it: "Grigio"},
    BLUE_LABEL_TEXT: {de: "Blau", it: "Blu"},
    OVERLAY_TABLE_POSITION_X_HEADER_TEXT: {de: "Position X", it: "Posizione X"},
    OVERLAY_TABLE_POSITION_Y_HEADER_TEXT: {de: "Position Y", it: "Posizione Y"},
    OVERLAY_TABLE_TYPE_HEADER_TEXT: {de: "Typ", it: "Tipo"},
    OVERLAY_TABLE_WIDTH_HEADER_TEXT: {de: "Breite", it: "Larghezza"},
    OVERLAY_TABLE_HEIGHT_HEADER_TEXT: {de: "Höhe", it: "Altezza"},
    COMPARISON_EQ_LABEL_TEXT: {de: "Gleich", it: "Uguale"},
    COMPARISON_NEQ_LABEL_TEXT: {de: "Ungleich", it: "Diverso"},
    COMPARISON_LT_LABEL_TEXT: {de: "Kleiner", it: "Minore"},
    COMPARISON_GT_LABEL_TEXT: {de: "Größer", it: "Maggiore"},
    COMPARISON_LEQ_LABEL_TEXT: {de: "Kleiner gleich", it: "Più piccolo uguale"}, 
    COMPARISON_GEQ_LABEL_TEXT: {de: "Größer gleich", it: "Più grande uguale"},
    OVERLAY_ELEMENT_BARGRAPH_TYPE: {de: "Bar Graph", it: "Bar Graph"},
    OVERLAY_ELEMENT_STATIC_TEXT_TYPE: {de: "Statischer Text", it: "Testo statico"},
    OVERLAY_ELEMENT_DYNAMIC_TEXT_TYPE: {de: "Dynamischer Text", it: "Testo dinamico"},
    OVERLAY_ELEMENT_DYNAMIC_GRAPHIC_TYPE: {de: "Dynamische Grafik", it: "Grafica dinamica"},
    OVERLAY_ELEMENT_STATIC_GRAPHIC_TYPE: {de: "Statische Grafik", it: "Grafica statica"},
    OVERLAY_ELEMENT_TEXT_LABEL_TEXT: {de: "Text", it: "Testo"},
    OVERLAY_ELEMENT_TEXT_SIZE_LABEL_TEXT: {de: "Textgröße", it: "Dimensione testo"},
    OVERLAY_ELEMENT_TEXT_COLOR_LABEL_TEXT: {de: "Farbe", it: "Colore"},
    OVERLAY_ELEMENT_TEXT_ORIENTATION_LABEL_TEXT: {de: "Ausrichtung", it: "Allineamento"},
    OVERLAY_ELEMENT_DECIMAL_PLACES_LABEL_TEXT: {de: "Anz. Nachkommastellen", it: "Numero di cifre decimali"},
    COMPARISON_LABEL_TEXT: {de: "Vergleich", it: "Comparazione"},
    OVERLAY_ELEMENT_GRAPHIC_ON_LABEL_TEXT: {de: "On Grafik", it: "Grafica on"},
    OVERLAY_ELEMENT_GRAPHIC_OFF_LABEL_TEXT: {de: "Off Grafik", it: "Grafica off"},
    OVERLAY_ELEMENT_SELECT_GRAPHIC_LABEL_TEXT: {de: "Grafik wählen", it: "Selezionare la grafica"},
    OVERLAY_TABLE_ADD_ELEMENT_LABEL_TEXT: {de: "Element hinzufügen", it: "Aggiungi"},
    DELETE_LABEL_TEXT: {de: "Löschen", it: "Cancella"},
    COLUMN_LABEL_TEXT: {de: "Spalte", it: "Colonna"},
    SPS_LABEL_TEXT: {de: "SPS", it: "PLC"},
    ALARM_VIEW_STATION_HEADER_LABEL_TEXT: {de: "Anlage", it: "Impianto"},
    ALARM_VIEW_DATE_HEADER_LABEL_TEXT: {de: "Datum", it: "Data"},
    ALARM_VIEW_NOTIFICATION_HEADER_LABEL_TEXT: {de: "Meldung", it: "Messaggio"},
    ALARM_VIEW_STATE_HEADER_LABEL_TEXT: {de: "Status", it: "Stato"},
    ALARM_VIEW_DOWNLOAD_TABLE_LABEL_TEXT: {de: "Tabelle herunterladen", it: "Scarica tabella"},
    ALARM_VIEW_RESET_TABLE_FILTER_LABEL_TEXT: {de: "Filter zurücksetzen", it: "Cancella filtri"},
    ALARM_VIEW_NO_ALARMS_NOTIFICATIONS_LABEL_TEXT: {de: "Keine Alarme", it: "Nessun allarme"},
    ALARM_CONTACTS_VIEW_HEADER_NAME_LABEL_TEXT: {de: "Name", it: "Nome"},
    ALARM_CONTACTS_VIEW_HEADER_EMAIL_LABEL_TEXT: {de: "Email", it: "Email"},
    ALARM_CONTACTS_VIEW_HEADER_PHONENUMBER_LABEL_TEXT: {de: "Telefonnummer", it: "Numero di telefono"},
    ALARM_CONTACTS_LABEL_TEXT: {de: "Alarmkontakte", it: "Contatti di allarme"},
    ADD_LABEL_TEXT: {de: "Hinzufügen", it: "Aggiungi"},
    ALARM_CONTACTS_NO_CONTACTS_NOTIFICATIONS_LABEL_TEXT: {de: "Keine Alarmkontakte", it: "Nessun contatto d'allarme"},
    ALARM_CONTACTS_VIEW_EMAIL_ACTIVE_LABEL_TEXT: {de: "Email aktiv", it: "Email attiva"},
    ALARM_CONTACTS_VIEW_PHONENUMBER_ACTIVE_LABEL_TEXT: {de: "SMS aktiv", it: "SMS attivo"},
    ALARM_CONTACTS_VIEW_NOTIFICATION_TITLE: {de: "Alarmkontakte", it: "Contatti d'allarme"},
    ALARM_CONTACTS_VIEW_NOTIFICATION_SUCCESSFUL_UPDATE_TEXT: {de: "Alarmkontakte aktualisiert", it: "Contatti d'allarme aggiornati"},
    ALARM_CONTACTS_VIEW_NOTIFICATION_ERROR_UPDATE_TEXT: {de: "Fehler beim Aktualisieren", it: "Errore durante l'aggiornamento"},
    GRAPHS_VIEW_FROM_DATEPICKER_LABEL: {de: "Von", it: "Da"},
    GRAPHS_VIEW_TO_DATEPICKER_LABEL: {de: "Bis", it: "A"},
    OVERLAY_TABLE_UPLOAD_GRAPHIC_LABEL_TEXT: {de: "Grafik hochladen", it: "Carica grafica"},
    EDITOR_SUCCESSFUL_GRAPHIC_UPLOAD_NOTIFICATION_TEXT: {de: "Grafik hochgeladen", it: "Grafica caricata"},
    EDITOR_ERROR_GRAPHIC_UPLOAD_NOTIFICATION_TEXT: {de: "Fehler beim Hochladen", it: "Errore durante il caricamento"},
    STATIONOVERVIEW_SUCCESSFUL_SUBMIT_TABLE_NOTIFICATION_TEXT: {de: "Tabelle gespeichert", it: "Tabella salvata"},
    STATIONOVERVIEW_ERROR_SUBMIT_TABLE_NOTIFICATION_TEXT: {de: "Fehler beim Speichern der Tabelle", it: "Errore durante il salvataggio della tabella"}
}

export const useContextTranslations = () => {
    const { userLanguage }: OutletDataContext = useOutletContext()
    if(userLanguage === "it")
        return (key: TranslationKey) => translations[key].it
    else
        return (key: TranslationKey) => translations[key].de
}

export const useTranslations = (lang: "de" | "it" | undefined) => {
    if(lang === "it"){
        return (key: TranslationKey) => translations[key].it
    }
    else {
        return (key: TranslationKey) => translations[key].de
    }
}