import { OverlayMetadata } from "../map/ElementDef";

export const availableOverlayEditElementTypes = ["BarOverlay", "StaticTextOverlay", "DynamicTextOverlay" , "DynamicImageOverlay", "StaticImageOverlay"] as const;
export type OverlayEditElementType = typeof availableOverlayEditElementTypes[number];

export type SpsColum = {
    description: string,
    displayId: number,
    name: string
}

export type Sps = {
    columns: SpsColum[],
    description: string,
    name: string,
    spsId: number
}

export type EditEnvironment = {
    sps: Sps[],
}

export type OverlayEditElement =
    { type: "BarOverlay", metadata: OverlayMetadata, data: BarEditOverlay } |
    { type: "StaticTextOverlay", metadata: OverlayMetadata, data: StaticTextEditOverlay } |
    { type: "DynamicTextOverlay", metadata: OverlayMetadata, data: DynamicTextEditOverlay } |
    { type: "DynamicImageOverlay", metadata: OverlayMetadata, data: DynamicImageOverlay } |
    { type: "StaticImageOverlay", metadata: OverlayMetadata, data: StaticImageOverlay } 

export const availableSpsDataColumns = [
    "DI1" , "DO1" ,
    "AI1" , "AI2" , "AI3" , "AI4" , "AI5" , "AI6" , "AI7" , "AI8" , "AI9" , "AI10" , "AI11" , "AI12" ,
    "AO1" , "AO2" , "AO3" , "AO4" , "AO5" , "AO6" , "AO7" , "AO8" ,
    "CTR1" , "CTR2" , "CTR3" , "CTR4" , "CTR5" , "CTR6" , "CTR7" , "CTR8" ,
    "STA1" , "STA2" , "STA3" , "STA4" , "STA5" , "STA6" , "STA7" , "STA8"
] as const;

export type SpsDataColumn = typeof availableSpsDataColumns[number];

export type BarEditOverlay = {
    displayId: number,
    color: string
};

export type StaticTextEditOverlay = {
    size: number,
    label: string,
    color: string,
    alignment: "start" | "center" | "end"
};

export type DynamicTextEditOverlay = {
    label: string | undefined,
    size: number,
    displayId: number,
    readonly unit: string,
    color: string,
    precision: number
    alignment: "start" | "center" | "end"
};

export const comparisonOperators = ["lt", "gt", "eq", "leq", "geq", "neq"]
export type Comp = typeof comparisonOperators[number]

export type Condition =  {
    comp: Comp,
    threshold: number,
};

export type DynamicImageOverlay = {
    displayId: number,
    condition: Condition,
    offGraphic: string,
    onGraphic: string,
};

export type StaticImageOverlay = { // TODO: add row element
    graphic: string
};

export type TableEditRow = {
    label: string,
    column: SpsDataColumn,
    unit: string,
};

export type TableEdit = {
    sps: number,
    name: string,
    inherit: number,
    rows: TableEditRow[]
};