import { useNavigate, useParams } from "react-router-dom"
import { 
    Button,
    ButtonSkeleton,
    Tile,
    FlexGrid,
    Row,
    Column,
    Loading
} from "@carbon/react"
import {
    Edit
} from "@carbon/icons-react"

import { useGroupOverviewAPI } from "../../hooks/useGroupOverviewAPI"
import { Map } from "../map/Map";
import { OverlayElement } from "../map/ElementDef";
import { useGroupOverviewImageAPIQuery } from "../../hooks/useGroupOverviewImageAPI";
import { FlexErrorMessage } from "../../components/FlexErrorMessage";
import { UserData, useUserAuth } from "../../hooks/useUserAuth";
import { useContextTranslations } from "../../utils/translations";


export const GroupOverview = () => {

    const { user } = useUserAuth()
    const { groupId } = useParams()
    const translations = useContextTranslations()
    const { isLoading: isLoadingData, data } = useGroupOverviewAPI(groupId as string)
    const { isLoading: isLoadingImage, isError: isImageError, data: image } = useGroupOverviewImageAPIQuery(groupId as string)
    const navigate = useNavigate()

    if(isLoadingData) {
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }

    const rowStyle = {
        marginTop: '16px'
    }

    const renderImage = () => {
        if(isLoadingImage) {
            return <div style={{display: 'flex', justifyContent: 'center', margin: 50, alignItems: 'center'}}>
                <Loading withOverlay={false} />
            </div>
        }
        if(isImageError){
            return <FlexErrorMessage errorMessage={translations("GROUPOVERVIEW_ERROR_IMAGE_LOADING")} />
        }
        return <div style={{
            position: 'relative',
            padding: '0',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Map
                image={image?.url as string}
                overlayElements={data?.overlays as OverlayElement[]}
            /> 
        </div>  
    }

    const renderEditButton = (user: UserData | undefined) => {
        if (user === undefined){
            return <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8}}>
                <ButtonSkeleton />
            </div>
        }
        if (user.isAdmin){
            return <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8}}>
                <Button kind="ghost" iconDescription={translations("GROUPOVERVIEW_EDIT_OVERVIEW_IMAGE_LABEL_TEXT")} onClick={() => navigate("/editor/" + groupId + "/overview")} hasIconOnly renderIcon={Edit}>{translations("EDIT_LABEL_TEXT")}</Button>
            </div>
        }
        else {
            return null
        }
    }

    return(
        <FlexGrid>
            <Row style={rowStyle}>
                <Column>
                    <h1>{data?.map.mapName}</h1>
                </Column>
            </Row>
            <Row style={rowStyle}>
                <Column>
                    <Tile>
                        { renderEditButton(user) }
                        { renderImage() }
                    </Tile>
                </Column>   
            </Row>
        </FlexGrid>
    )
}