import { BarOverlay, DynamicTextOverlay, ImageOverlay, OverlayElement, StaticTextOverlay } from "./ElementDef";

export type MapProps = {
    image: string,
    overlayElements: OverlayElement[]
};

export const Map = (props: MapProps) => {
    const { overlayElements, image } = props

    return <>
        <div style={{
            position: 'relative',
            padding: '0',
            width: 'fit-content',
            height: 'fit-content',
            overflow: 'hidden'
        }}>
            <img
                alt=""
                src={image}
                style={{
                    width: '100%',
                    maxWidth: '100%',
                }}   
            />
            { overlayElements.map(renderOverlayElement) }
        </div>
    </>
}

function renderOverlayElement(element: OverlayElement) {
    let style = styleFromOverlayMetadata(element);
    let renderElement = null

    if (element.type === "BarOverlay") {
        renderElement = renderBarGraph(element.data, style);
    } else if (element.type === "StaticTextOverlay") {
        renderElement = renderStaticText(element.data, style);
    } else if (element.type === "DynamicTextOverlay") {
        renderElement = renderDynamicTextOverlay(element.data, style);
    } else if (element.type === "ImageOverlay") {
        renderElement = renderGifOverlay(element.data, style);
    }

    if(element.metadata.ref === ''){
        return <div key={element.metadata.id}>{renderElement}</div>
    }
    else {
        return <a key={element.metadata.id} href={element.metadata.ref}>{renderElement}</a>
    }
}

function styleFromOverlayMetadata({ type, metadata, data }: OverlayElement) {
    let typeStyles = {}
    if(type === "StaticTextOverlay" || type === "BarOverlay" || type === "DynamicTextOverlay"){
        typeStyles = {...typeStyles, color: data.color}
    }
    if(type === "StaticTextOverlay" || type === "DynamicTextOverlay"){
        typeStyles = {...typeStyles, textAlign: data.alignment}
    }
    return {
        position: "absolute",
        left: metadata.x + '%',
        top: metadata.y + '%',
        width: metadata.width + '%',
        height: metadata.height + '%',
        ...typeStyles
    }
}

function renderBarGraph(bargraph: BarOverlay, style: any) {
    style.background = `linear-gradient(0deg, ${bargraph.color} ${bargraph.value}%, #0000 0)`

    return <div style={style} />;
}

function renderStaticText(text: StaticTextOverlay, style: any) {
    style.fontSize = text.fontSize + 'px';

    return <div style={style}>{text.label}</div>;
}

function renderDynamicTextOverlay(text: DynamicTextOverlay, style: any) {
    style.fontSize = text.fontSize + 'px';
    return <div style={style}>{text.label}{text.value.toFixed(text.precision)} {text.unit}</div>;
}

function renderGifOverlay(gif: ImageOverlay, style: any) {
    if (gif.image === null) return <></>
    return <img src={gif.image} alt="GIF Overlay" style={{position: 'absolute', ...style, height: "auto"}}/>;
}