import {
    Error
} from "@carbon/icons-react"

export type FlexErrorMessageProps = {
    errorMessage: string
}

export const FlexErrorMessage = ({ errorMessage }: FlexErrorMessageProps) => {
    return <>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 50, alignItems: 'center'}}>
            <Error color="red"/>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 50, padding: 10, alignItems: 'center'}}>
            { errorMessage }
        </div>
    </>
}