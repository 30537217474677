import {
    Modal,
    StructuredListWrapper,
    StructuredListBody,
    StructuredListRow,
    StructuredListCell,
    Toggle,
    TextInput,
    Button,
} from "@carbon/react"
import { useContextTranslations } from "../../utils/translations"
import { TableOptions, useStationOverviewEditorAPI } from "../../hooks/useStationOverviewEditorAPI"
import { StationOverviewTableData } from "../../hooks/useStationOverviewAPI"
import { useEffect, useRef, useState } from "react"
import { Edit } from "@carbon/icons-react"

export type StationOverviewTableModalProps = {
    groupId: string,
    stationId: string,
    open: boolean,
    onRequestClose: () => void,
    onSubmit: (tables: any) => void,
    tableData: StationOverviewTableData,
    currentTables: { title: string, rows: any[] }[]
}

export const StationOverviewTableModal = (props: StationOverviewTableModalProps) => {
    const translations = useContextTranslations()
    const { isLoading, data } = useStationOverviewEditorAPI(props.groupId, props.stationId)
    const [activeRowNames, setActiveRowNames] = useState(props.tableData.rows.map(row => row.name))
    const [currentTables, setCurrentTables] = useState(props.currentTables)
    const [showTableTitleInput, setTableTitleInputVisibility] = useState(false)
    const [tableTitle, setTableTitle] = useState(props.tableData.title)
    const inputRef = useRef<HTMLInputElement | null>(null);

    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setTableTitleInputVisibility(false);
            }
        };

        if (showTableTitleInput) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTableTitleInput]);
    
    const changeActiveRowsOnToggle = (toggeled: boolean, row: TableOptions) => {
        let table = currentTables.find(table => table.title === props.tableData.title)
        if (table !== undefined){
            if (toggeled){
                table.rows.push(row)
                setActiveRowNames(rows => [...rows, row.name])
            }
            else {
                table.rows = table.rows.filter(r => r.name !== row.name)
                setActiveRowNames(rows => rows.filter(r => r !== row.name))
            }
        }
    }

    if(isLoading || data === undefined){
        return null
    }

    const tableTitleInput = <TextInput 
        ref={inputRef}
        id="test2" 
        labelText="" 
        placeholder="Table Title"
        value={tableTitle}
        onChange={(e) => {
            setCurrentTables(currentTables.map(table => table.title === tableTitle ? {title: e.target.value, rows: table.rows} : table))
            setTableTitle(e.target.value)
        }}
    />

    const titleDiv = <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
        <div>
            {tableTitle}
        </div>
        <div style={{marginLeft: '8px'}}>
            <Button 
                kind="ghost" 
                iconDescription={translations("STATIONOVERVIEW_TABLE_MODIFY_TABLETITLE_BUTTON_LABEL_TEXT")} 
                onClick={() => setTableTitleInputVisibility(v => !v)} 
                hasIconOnly 
                renderIcon={Edit}>
                    {translations("STATIONOVERVIEW_TABLE_MODIFY_TABLETITLE_BUTTON_LABEL_TEXT")}
            </Button> 
        </div>
    </div>

    return (
        <Modal 
            open={props.open} 
            modalHeading={showTableTitleInput ? tableTitleInput : titleDiv}
            modalLabel={translations("STATIONOVERVIEW_TABLE_MODAL_LABEL")} 
            onRequestClose={props.onRequestClose}
            onRequestSubmit={() => props.onSubmit(currentTables)} 
            primaryButtonText={translations("SAVE_LABEL_TEXT")} 
            secondaryButtonText={translations("ABORT_LABEL_TEXT")}
        >
            <StructuredListWrapper isCondensed style={{maxWidth: '100%'}}>
                <StructuredListBody>
                    {
                        data.table_options.map((row, index) => <StructuredListRow>
                            <StructuredListCell>{row.name}</StructuredListCell>
                            <StructuredListCell>
                                <Toggle onToggle={(toggled) => changeActiveRowsOnToggle(toggled, row)} toggled={activeRowNames.includes(row.name)} hideLabel id={"stationview-overviewtable-toggle-"+index} />
                            </StructuredListCell>
                        </StructuredListRow>)
                    }
                </StructuredListBody>
            </StructuredListWrapper>
        </Modal>
    )
}