import React, { useState } from "react"

import {
    Header,
    HeaderName,
    SideNav,
    SideNavItems,
    SideNavMenu, 
    SideNavLink,
    SkipToContent,
    Content,
    HeaderContainer,
    HeaderMenuButton,
    SideNavDivider,
    Loading,
    ToastNotification
} from "@carbon/react"
import { Settings, Logout } from "@carbon/icons-react"
import { Outlet, useNavigate } from "react-router"

import { useBreakpoints } from "../../hooks/useBreakpoints"
import { SideMenuGroupData, SideMenuOverviewData, SideMenuStationData, useIndexAPI } from "../../hooks/useIndexAPI"
import { useUserAuth } from "../../hooks/useUserAuth"
import { useTranslations } from "../../utils/translations"

type Notification = {
    id: number,
    kind: "success" | "error" | "info" | "info-square" | "warning" | "warning-alt"
    title: string,
    caption?: string,
    subtitle?: string,
    timeout?: number   
}

type NotificationContextProps = {
    notifications: Notification[];
    pushNotification: (notification: Omit<Notification, "id">) => void;
}

export type OutletDataContext = {
    getGroupNameformId: (grpId: string) => string | undefined,
    getStationNamefromId: (grpId: string, stationId: string) => string | undefined
    userLanguage: string | undefined
}

const voidNotificationContext = {notifications: [], pushNotification: (x: Omit<Notification, "id">) => {return}}
export const NotificationContext = React.createContext<NotificationContextProps>(voidNotificationContext)

export const GlobalMenu = () => {

    const { logoutUser, user } = useUserAuth()
    const navigate = useNavigate()
    const translations = useTranslations(user?.language)
    const { isLg: useResponsiveMargin } = useBreakpoints()
    const { isLoading, data, isError, error } = useIndexAPI()
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [activeMenupoint, setActiveMenupoint] = useState({groupId: -1, stationId: -1, key: ''})

    const getNextId = () => notifications.length > 0 ? Math.max(...notifications.map(n => n.id)) + 1 : 0
    const notificationContext = {notifications: notifications, pushNotification: (notification: Omit<Notification, "id">) => setNotifications([...notifications, {id: getNextId(), ...notification}])}
    
    if(isLoading) {
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }
    if(isError) {
        return <div>{error.toString()}</div>
    }

    const renderStationTree = (stationData: SideMenuStationData, groupId: number) => {
        
        const onLinkClick = (endpoint: string) => {
            const stationBaseUrl = "/" + groupId + "/stations/" + stationData.spsId
            navigate(stationBaseUrl + "/" + endpoint)
            setActiveMenupoint({ groupId: groupId, stationId: stationData.spsId, key: getLinkKey(endpoint)})
        }

        const getLinkKey = (endpoint: string) => {
            return stationData.spsId + "-" + endpoint
        }

        return(
            <div style={{paddingLeft: "16px"}}>
                <SideNavMenu title={stationData.spsName} defaultExpanded={stationData.spsId === activeMenupoint.stationId}>
                    <SideNavLink key={getLinkKey("overview")} onClick={() => onLinkClick("overview")} isActive={getLinkKey("overview") === activeMenupoint.key}>
                        {translations("GLOBAL_MENU_DETAIL_OVERVIEW_LABEL_TEXT")}
                    </SideNavLink> 
                    <SideNavLink key={getLinkKey("graphs")} onClick={() => onLinkClick("graphs")} isActive={getLinkKey("graphs") === activeMenupoint.key}>
                        {translations("GLOBAL_MENU_GRAPHICS_LABEL_TEXT")}
                    </SideNavLink> 
                    <SideNavLink key={getLinkKey("alarm-contacts")} onClick={() => onLinkClick("alarm-contacts")} isActive={getLinkKey("alarm-contacts") === activeMenupoint.key}>
                        {translations("GLOBAL_MENU_ALARM_CONTACTS_LABEL_TEXT")}
                    </SideNavLink> 
                </SideNavMenu>
            </div>
        )
    }

    const renderGroupOverview = (overviewData: SideMenuOverviewData, groupId: number) => {
        
        const onLinkClick = (endpoint: string) => {
            navigate("/" + overviewData.mapId + "/" + endpoint)
            setActiveMenupoint({ groupId: groupId, stationId: -1, key: overviewData.mapId.toString()})
        }
        
        return (
            <SideNavLink key={overviewData.mapId} onClick={() => onLinkClick("overview")} isActive={overviewData.mapId.toString() === activeMenupoint.key}>
                {overviewData.mapName}
            </SideNavLink>
        )
    }

    const renderGroupTree = (menuData: SideMenuGroupData) => {
        
        const onLinkClick = (endpoint: string) => {
            navigate("/" + menuData.groupId + "/" + endpoint)
            setActiveMenupoint({ groupId: menuData.groupId, stationId: -1, key: getLinkKey("alarms")})
        }

        const getLinkKey = (endpoint: string) => {
            return menuData.groupId + "-" + endpoint
        }

        return(
            <SideNavMenu title={menuData.groupName} defaultExpanded={menuData.groupId === activeMenupoint.groupId}>
                {menuData.overviews.map(elem => renderGroupOverview(elem, menuData.groupId))}
                <SideNavLink key={getLinkKey("alarms")} onClick={() => onLinkClick("alarms")} isActive={getLinkKey("alarms") === activeMenupoint.key}>
                    {translations("ALARM_LABEL_TEXT")}
                </SideNavLink> 
                {menuData.stations.map(elem => renderStationTree(elem, menuData.groupId))}
            </SideNavMenu>
        )
    }

    const outletContext: OutletDataContext = {
        getGroupNameformId: (grpId: string) => data?.filter(g => g.groupId === parseInt(grpId)).map(g => g.groupName).pop(),
        getStationNamefromId: (grpId: string, stationId: string) => data?.filter(g => g.groupId === parseInt(grpId)).pop()?.stations.filter(s => s.spsId === parseInt(stationId)).pop()?.spsName,
        userLanguage: user?.language
    }

    return(
        <HeaderContainer render={({isSideNavExpanded, onClickSideNavExpand}) => 
            <>
                <Header aria-label="" aria-labelledby="" >
                    <SkipToContent />
                    <HeaderMenuButton onClick={onClickSideNavExpand} isActive={isSideNavExpanded} aria-label="" aria-labelledby="" />
                    <HeaderName href="#" prefix="ELSO">
                        Dashboard
                    </HeaderName>
                    <SideNav expanded={isSideNavExpanded} onSideNavBlur={onClickSideNavExpand} placeholder="Sidenav" aria-label="" aria-labelledby="" >
                        <SideNavItems>
                            {data?.map(renderGroupTree)}
                            <SideNavDivider />
                        <SideNavLink renderIcon={Settings} onClick={() => navigate("/settings")}>
                            {translations("SETTINGS_LABEL_TEXT")}
                        </SideNavLink>
                        <SideNavLink renderIcon={Logout} onClick={logoutUser}>
                            {translations("LOGOUT_LABEL_TEXT")}
                        </SideNavLink>
                        </SideNavItems>
                    </SideNav>
                    <div style={{zIndex: 9, maxWidth: '100%', position: 'absolute', right: 32, top: 64}}> 
                    {
                        notifications.map(notification => <ToastNotification 
                            role="log" 
                            kind={notification.kind} 
                            caption={notification.caption} 
                            timeout={notification.timeout ? notification.timeout : 3000}  
                            title={notification.title} 
                            subtitle={notification.subtitle} 
                            onClose={() => setNotifications(notifications.filter(n => n.id !== notification.id))}
                            style={{marginTop: 8}}
                        />)
                    }    
                    </div>
                </Header>
                <Content style={{marginLeft: useResponsiveMargin ? '16rem': undefined}}>
                    <NotificationContext.Provider value={notificationContext}>
                        <Outlet context={outletContext}/>
                    </NotificationContext.Provider>
                </Content>
            </>
        } />

    )
}