import { useQuery } from "@tanstack/react-query"
import { apiHost } from "../config/apiConfig"


export type SpsColumn = {
    description: string,
    displayId: number,
    name: string
}

export type SpsColumnData = {
    columns: SpsColumn[],
    description: string,
    name: string,
    spsId: number
}

export type APISpsColumn = {
    description: string,
    display_id: number,
    name: string
}

export type SpsColumnAPIData = {
    columns: APISpsColumn[],
    description: string,
    name: string,
    sps_id: number
}

const mapAPIDataToAPISpsColum = (apiData: APISpsColumn): SpsColumn => {
    return {
        description: apiData.description,
        displayId: apiData.display_id,
        name: apiData.name
    }
}

const mapAPIDataToReturnType = (apiData: SpsColumnAPIData): SpsColumnData => {
    return {
        columns: apiData.columns.map(mapAPIDataToAPISpsColum),
        description: apiData.description,
        name: apiData.name,
        spsId: apiData.sps_id
    }
}

export const spsColumnAPIKey = "spsColumnAPI"

export const useSpsColumnAPI = (groupId: number) => {
    const url = apiHost + '/editor/' + groupId + '/sps_columns'
    const query = useQuery<SpsColumnData[]>({
        queryKey: [spsColumnAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data: SpsColumnAPIData[] = await res.json()
            return data.map(mapAPIDataToReturnType)
        }
    })
    return query
}