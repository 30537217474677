import { useMutation, useQuery } from "@tanstack/react-query"
import { apiHost, queryClient } from "../config/apiConfig"

export const alarmContactsAPIKey = "alarmContacts"

type AlarmContactAPIData = {
    name: string, 
    email: string, 
    email_active: boolean,
    sms: string,
    sms_active: boolean
}

export type AlarmContact = {
    id: string,
    name: string, 
    email: string, 
    emailActive: boolean,
    sms: string,
    smsActive: boolean 
}

const apiAlarmContactsToAlarmContacts = (apiData: AlarmContactAPIData[]): AlarmContact[] => {
    const contactsWithId = []
    for (let idx = 0; idx < apiData.length; idx++) {
        const contact = {
            id: idx.toString(),
            name: apiData[idx].name,
            email: apiData[idx].email,
            emailActive: apiData[idx].email_active,
            sms: apiData[idx].sms,
            smsActive: apiData[idx].sms_active 
        }
        contactsWithId.push(contact)
    }
    return contactsWithId
}

const alarmContactsToAPIAlarmContacts = (contact: AlarmContact): AlarmContactAPIData => {
    return {
        name: contact.name, 
        email: contact.email, 
        email_active: contact.emailActive,
        sms: contact.sms,
        sms_active: contact.smsActive
    }
}

export const useAlarmContactsAPI = (groupId: string, stationId: string) => {
    const url = apiHost + groupId + "/stations/" + stationId + "/alarm-contacts"
    const query = useQuery({
        queryKey: [alarmContactsAPIKey, groupId, stationId],
        queryFn: async () => {
            const res = await fetch(url)
            let data: AlarmContactAPIData[] = await res.json()
            return apiAlarmContactsToAlarmContacts(data)
        }
    })
    return query
}

export const useAlarmContactsAPIMutation = (groupId: string, stationId: string) => {
    const mutation = useMutation({
        mutationFn: (editElements: AlarmContact[]) => {
            const apiElements = editElements.map(alarmContactsToAPIAlarmContacts)
            return fetch(apiHost + groupId + "/stations/" + stationId + "/alarm-contacts", {
                method: "POST",
                body: JSON.stringify(apiElements),
                headers: {
                    "Content-Type": "application/json",
                },
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [alarmContactsAPIKey, groupId, stationId] })
            queryClient.invalidateQueries({ queryKey: [alarmContactsAPIKey, groupId, stationId] })
        }
    })
    return mutation
}