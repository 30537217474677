import { useQuery } from "@tanstack/react-query"
import { OverlayElement, OverlayMetadata } from "../views/map/ElementDef"
import { apiHost } from "../config/apiConfig"

export type GroupOverviewAPIData = {
    map: {
        image: string,
        mapId: number,
        mapName: string
    },
    overlays: OverlayElement[]
}

const mapAPIMetadataToOverlayMetadata = (apiMetadata: any): OverlayMetadata => {
    return {
        id: apiMetadata.overlay_id,
        x: apiMetadata.x,
        y: apiMetadata.y,
        width: apiMetadata.width,
        height: apiMetadata.height,
        ref: apiMetadata.ref
    }
}

const mapAPIOverlayElementsToOverlayElements = (apiData: any): OverlayElement | undefined => {
    switch(apiData.type){
        case "BarOverlay": 
        return {
            type: "BarOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiData.metadata),
            data: {
                color: apiData.data.color,
                value: apiData.data.value
            }
        };
        case "StaticTextOverlay": 
        return {
            type: "StaticTextOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiData.metadata),
            data: {
                fontSize: apiData.data.font_size,
                alignment: apiData.data.alignment,
                label: apiData.data.label,
                color: apiData.data.color
            }
        };
        case "DynamicTextOverlay": return {
            type: "DynamicTextOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiData.metadata),
            data: {
                fontSize: apiData.data.font_size,
                alignment: apiData.data.alignment,
                label: apiData.data.label,
                value: apiData.data.value,
                unit: apiData.data.unit,
                color: apiData.data.color,
                precision: apiData.data.precision
            }
        };
        case "ImageOverlay": return {
            type: "ImageOverlay",
            metadata: mapAPIMetadataToOverlayMetadata(apiData.metadata),
            data: {
                image: apiData.data.image
            }
        };
        default: return undefined
    }
}

const mapAPIDataToReturnType = (apiData: any): GroupOverviewAPIData => {
    return {
        map: {
            image: apiData.map.image,
            mapId: apiData.map.map_id,
            mapName: apiData.map.name
        },
        overlays: apiData.overlays.map(mapAPIOverlayElementsToOverlayElements).filter((e: OverlayElement | undefined) => e !== undefined)
    }
}

export const groupOverviewAPIKey = "groupOverview"

export const useGroupOverviewAPI = (groupId: string) => {
    const url = apiHost + groupId + "/overview"
    const query = useQuery<GroupOverviewAPIData>({
        queryKey: [groupOverviewAPIKey, groupId],
        queryFn: async () => {
            const res = await fetch(url)
            let data: any = await res.json()
            return mapAPIDataToReturnType(data)
        }
    })
    return query
}