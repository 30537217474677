import { useMutation, useQuery } from "@tanstack/react-query"
import { apiHost, queryClient } from "../config/apiConfig"

export type GroupOverviewImageAPIData = {
    url: string
}

export const groupOverviewImageAPIKey = "groupOverviewImage"

export const useGroupOverviewImageAPIQuery = (groupId: string) => {
    const imageUrl = apiHost + groupId + "/overview/image"
    const query = useQuery<GroupOverviewImageAPIData>({
        queryKey: [groupOverviewImageAPIKey, groupId],
        queryFn: async () => {
            const img_res = await fetch(imageUrl)
            const img = await img_res.blob()
            return {
                url: URL.createObjectURL(img)
            }
        }
    })
    return query
}

export const useGroupOverviewImageAPIMutation = (groupId: string) => {
    const imageUrl = apiHost + groupId + "/overview/image"
    const mutation = useMutation({
        mutationFn: (files: FileList | null) => {
            if (files === null || files.length === 0 ) {
                throw new Error("No file selected")
            }
            const file = files[0]
            const formData = new FormData();
            formData.append("image", file);
            return fetch(imageUrl, {
                method: "POST",
                body: formData,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [groupOverviewImageAPIKey, groupId] })
        }
    })
    return mutation
}