import { useOutletContext, useParams } from "react-router-dom"
import { LineChart } from '@carbon/charts-react'
import {
  DatePicker,
  DatePickerInput,
  Loading
} from "@carbon/react"
import { LineChartOptions, ScaleTypes, ChartTheme } from '@carbon/charts';
import { StationGraphDataPoint, useStationGraphsAPI } from "../../hooks/useStationGraphsAPI";
import { OutletDataContext } from "../menu/GlobalMenu";
import { useState } from "react";
import { useContextTranslations } from "../../utils/translations";

type GraphDataPoint = {
  "group": string,
  "date": string,
  "value": number,
}

const getDateString = (date: Date) => date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()

const subtractDays = (date: Date, days: number = 31) => {
  let d = new Date(date)
  d.setDate(d.getDate() - days)
  return d
}

export const GraphicsView = () => {
    
    const defaultToDate = new Date()
    const defaultFromDate = subtractDays(defaultToDate)
    const translations = useContextTranslations()
    const context: OutletDataContext = useOutletContext()
    const { groupId, stationId } = useParams()
    const [ chartDates, setChartDates ] = useState({from: defaultFromDate, to: defaultToDate})
    const query = useStationGraphsAPI(groupId as string, stationId as string, getDateString(chartDates.from), getDateString(chartDates.to))

    if(query.isLoading || query.data === undefined){
      return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
          <Loading withOverlay={false} />
      </div> 
    }

    const legendData = new Map(query.data.legend.map(item => ([item.column, item])))

    const splitAPIData = (data: StationGraphDataPoint[]) => {
      let newData: GraphDataPoint[] = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        const newElements = Object.entries(element).map(([colName, value]) => {
          if(colName !== 'time'){
            let groupName = legendData.get(colName)?.name
            if(legendData.get(colName)?.unit !== ''){
              groupName = groupName + " (" + legendData.get(colName)?.unit + ")"
            }
            return {
              "group": groupName,
              "date": element.time,
              "value": value,
            }
          }
          return {"date": undefined}
        }).filter(elem => elem?.date !== undefined)
        newData = [...newData, ...newElements as GraphDataPoint[]]
      }
      return newData
    }

    const options: LineChartOptions = {
        axes: {
            bottom: {
                title: "Datum",
                mapsTo: "date",
                scaleType: ScaleTypes.TIME,
            },
            left: {
                mapsTo: "value",
                title: "Wert",
                scaleType: ScaleTypes.LINEAR,
            }
        },
        curve: "curveMonotoneX",
        height: "400px",
        theme: ChartTheme.WHITE
    }

    const chartData = splitAPIData(query.data.data)
    return(
      <div>
        <h2>{"Grafik " + context.getStationNamefromId(groupId as string, stationId as string)}</h2>    
        <div style={{display: 'flex', justifyContent: 'end', margin: '8px'}}>
          <DatePicker style={{maxWidth: 'max-content'}} locale={context.userLanguage} datePickerType="range" dateFormat="d/m/Y" onClose={([from, to]) => setChartDates({from: from as Date, to: to as Date})} value={[chartDates.from, chartDates.to]}>
            <DatePickerInput id="date-picker-input-id-start" placeholder="dd/mm/yyyy" labelText={translations("GRAPHS_VIEW_FROM_DATEPICKER_LABEL")} size="sm" />
            <DatePickerInput id="date-picker-input-id-finish" placeholder="dd/mm/yyyy" labelText={translations("GRAPHS_VIEW_TO_DATEPICKER_LABEL")} size="sm" />
          </DatePicker>
        </div>
        <LineChart
            data={chartData}
            options={options}
        ></ LineChart>
      </div>
    )
}