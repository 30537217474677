import { useQuery } from "@tanstack/react-query"
import { OverlayElement } from "../views/map/ElementDef"
import { apiHost } from "../config/apiConfig"

export type StationOverviewTableRow = {
    description: string,
    name: string,
    position: number,
    value: string
}

export type StationOverviewTableData = {
    rows: StationOverviewTableRow[]
    table_id: number
    title: string
}


export type StationOverviewAPIData = {
    map: {
        image: string,
        map_id: number,
        name: string
    },
    overlays: OverlayElement[],
    tables: StationOverviewTableData[]
}

export const stationOverviewAPIKey = "stationOverview"

export const useStationOverviewAPI = (groupId: string, stationId: string) => {
    const url = apiHost + groupId + "/stations/" + stationId + "/overview"
    const query = useQuery<StationOverviewAPIData>({
        queryKey: [stationOverviewAPIKey],
        queryFn: async () => {
            const res = await fetch(url)
            const data = await res.json()
            return data
        }
    })
    return query
}