import React, { useEffect } from "react"
import { useUserAuth } from "../hooks/useUserAuth"
import { useNavigate } from "react-router-dom"
import {
    Loading,
    Button
} from "@carbon/react"

type IsAdminViewProps = {
    children: React.ReactNode
}

export const IsAdminView = (props: IsAdminViewProps): JSX.Element => {
    
    const { user, isLoggedIn } = useUserAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!isLoggedIn){
            navigate("/login")
        }
    }, [isLoggedIn, user, navigate])

    if(!isLoggedIn || user === undefined){
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center'}}>
            <Loading withOverlay={false} />
        </div>
    }
    else if (!user.isAdmin) {
        return <div style={{marginTop: 200}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Missing permissions for this side
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button kind="ghost" onClick={() => navigate(-1)}>Return to previous page</Button>
            </div>
        </div>
        
    }
    else {
        return props.children as JSX.Element
    }
}