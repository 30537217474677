import {
    Button,
    ButtonSkeleton,
} from "@carbon/react"

import {
    Add,
} from "@carbon/icons-react"

import { UserData } from "../../hooks/useUserAuth"
import { useContextTranslations } from "../../utils/translations"
import { useStationOverviewAPI } from "../../hooks/useStationOverviewAPI"
import { TableOptions, useStationOverviewEditorAPI, useStationOverviewEditorAPIMutation } from "../../hooks/useStationOverviewEditorAPI"
import { NotificationContext } from "../menu/GlobalMenu"
import { useContext } from "react"

export type StationOverviewAddTableButtonProps = {
    groupId: string,
    stationId: string,
    user: UserData | undefined
}

export const StationOverviewAddTableButton = (props: StationOverviewAddTableButtonProps) => {
    const translations = useContextTranslations()
    const { data: stationData } = useStationOverviewAPI(props.groupId, props.stationId)
    const { data: editorData } = useStationOverviewEditorAPI(props.groupId, props.stationId)
    const { pushNotification } = useContext(NotificationContext)
    const mutation = useStationOverviewEditorAPIMutation(stationData === undefined ? "" : stationData.map.map_id.toString())

    if (props.user === undefined){
        return <ButtonSkeleton />
    }
    if (stationData === undefined || editorData === undefined){
        return null
    }

    const computeCurrentTableOptions = () => { 
        let oldTables: {title: string, rows: any}[] = []
        for (let index = 0; index < stationData.tables.length; index++) {
            let stationTable = stationData.tables[index]
            let stationTableOptions: TableOptions[] = []
            for (let rowIndex = 0; rowIndex < stationTable.rows.length; rowIndex++) {
                const row = stationTable.rows[rowIndex]
                const table_option = editorData.table_options.filter(option => option.name === row.name).pop()
                if (table_option !== undefined){
                    stationTableOptions.push(table_option)
                }
            }
            oldTables.push({ title: stationTable.title, rows: stationTableOptions})
        }
        return oldTables
    }

    const appendNewTable = () => {
        const newItem = {title: "New Table", rows: []}
        const oldTables = computeCurrentTableOptions()
        mutation.mutate([ ...oldTables, newItem], {
            onSuccess: () => pushNotification({
                kind: "success",
                title: translations("STATIONOVERVIEW_SUCCESSFUL_ADD_TABLE_NOTIFICATION_TEXT"),
                timeout: 700
            }),
            onError: () => pushNotification({
                kind: "error",
                title: translations("STATIONOVERVIEW_ERROR_ADD_TABLE_NOTIFICATION_TEXT"),
                timeout: 700
            })
        })
    }
    
    return props.user.isAdmin ? <Button 
            kind="ghost" 
            iconDescription={translations("STATIONOVERVIEW_TABLE_ADD_TABLE_BUTTON_LABEL_TEXT")} 
            onClick={() => appendNewTable()} 
            hasIconOnly 
            renderIcon={Add}>
                {translations("STATIONOVERVIEW_TABLE_ADD_TABLE_BUTTON_LABEL_TEXT")}
            </Button> 
            : null
}